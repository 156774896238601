import MDBox from "components/MDBox";
import { mdiCreditCardOutline, mdiDownload, mdiPrinterOutline } from "@mdi/js";
import { StyledButtonWithIcon } from "pages/dashboard/home/site-lists/components/SiteListActionBar";
import { Card } from "@mui/material";
import DashboardContentLayout from "layouts/DashboardContentLayout";
import { PlaceholderComponent } from "pages/dashboard/home/dashboard-home/components/PlaceholderComponent";
import { useEffect, useState } from "react";
import { getInvoiceByID } from "../services/service";
import { GENERIC_ERROR_MESSAGE, WebServiceStatus } from "@ivueit/vue-engine";
import { useLocation } from "react-router-dom";
import Base64PDFViewer from "./Base64PDFViewer";
import CustomSnackbar, {
  CustomSnackbarContent,
} from "pages/components/CustomSnackbar";
import { CustomIndicator } from "pages/components/CustomIndicator";
import PdfViewFix from "./PdfViewFix";
import {
  downloadInvoiceAsPDF,
  generateURLFromBase64String,
} from "../helpers/helper_methods";

const InvoicePreview = () => {
  const location = useLocation();
  const [loading, setLoading] = useState<boolean>(false);
  const [base64String, setBase64String] = useState<string | null>(null);
  const [pdfUrl, setPdfUrl] = useState<string | null>(null);
  const [invoiceId, setInvoiceId] = useState<string | null>(null);
  const [snackbarContent, setSnackbarContent] =
    useState<CustomSnackbarContent | null>(null);

  useEffect(() => {
    const fetchInvoice = async (invoiceId: string) => {
      setLoading(true);
      const response = await getInvoiceByID(invoiceId);
      if (response.status === WebServiceStatus.success) {
        const responseData = response.data;
        const fileData = responseData.data;
        if (fileData) {
          setBase64String(fileData);
          const url = generateURLFromBase64String(fileData, "application/pdf");
          setPdfUrl(url);
        } else {
          setSnackbarContent({
            title: "Attention!",
            message: "Could not preview the voice now. Please try again later.",
            isError: true,
          });
        }
      } else {
        setSnackbarContent({
          title: "Attention!",
          message: response.error ?? GENERIC_ERROR_MESSAGE,
          isError: true,
        });
      }
      setLoading(false);
    };

    if (invoiceId) {
      fetchInvoice(invoiceId);
    }
  }, [invoiceId]);

  useEffect(() => {
    if (location.state) {
      const { invoiceId } = location.state;
      if (invoiceId) {
        setInvoiceId(invoiceId);
      }
    }
  }, [location.state]);

  const onClickDownloadButton = async () => {
    const hasDownloaded = (await downloadInvoiceAsPDF(
      base64String,
      invoiceId
    )) as boolean;
    if (hasDownloaded) {
      setSnackbarContent({
        title: "Success",
        message: "PDF downloaded successfully",
        isError: false,
      });
    } else {
      setSnackbarContent({
        title: "Attention!",
        message: "Failed to download the invoice",
        isError: true,
      });
    }
  };

  const onClickPrintInvoice = () => {
    /// Creating link element to open the pdf file in new page
    const link = document.createElement("a");
    link.href = pdfUrl;
    link.target = "_blank"; // To Open in a new tab
    /// The anchor element is appended to the document body and removes when download is done
    document.body.appendChild(link);
    /// Click event triggers the download
    link.click();
    document.body.removeChild(link);
  };
  const onClickPayButton = () => {};

  return (
    <DashboardContentLayout needCardBackground={false}>
      <MDBox>
        <MDBox display="flex" pt={0.8}>
          <MDBox mr={1.6}>
            <StyledButtonWithIcon
              iconPath={mdiDownload}
              onClick={onClickDownloadButton}
              disabled={!pdfUrl}
            >
              Download
            </StyledButtonWithIcon>
          </MDBox>
          <MDBox mr={1.6}>
            <StyledButtonWithIcon
              iconPath={mdiPrinterOutline}
              onClick={onClickPrintInvoice}
              disabled={!pdfUrl}
            >
              Print
            </StyledButtonWithIcon>
          </MDBox>
          <MDBox mr={1.6} display="none">
            <StyledButtonWithIcon
              iconPath={mdiCreditCardOutline}
              onClick={onClickPayButton}
              disabled={!pdfUrl}
            >
              Pay
            </StyledButtonWithIcon>
          </MDBox>
        </MDBox>
        <Card
          sx={{
            marginTop: "14px",
            height: "calc(100vh - 165px)",
            paddingY: "5px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <MDBox
            sx={{
              height: "100%",
              width: "690px",
              mx: "auto",
              position: "relative",
            }}
          >
            <PdfViewFix />
            {loading && <CustomIndicator />}
            {pdfUrl && (
              <Base64PDFViewer pdfUrl={pdfUrl} title={"Invoice Preview"} />
            )}
            {!pdfUrl && !loading && (
              <PlaceholderComponent label="Preview not available now" />
            )}
          </MDBox>
        </Card>
        <CustomSnackbar
          snackbarContent={snackbarContent}
          onClose={() => {
            setSnackbarContent(null);
          }}
        />
      </MDBox>
    </DashboardContentLayout>
  );
};

export default InvoicePreview;
