import allCountryAndStateList from "assets/jsons/statelist.json";
// import moment from "moment";
import moment from "moment-timezone";
import imageCompression from "browser-image-compression";
/* eslint-disable no-extend-native */
export {};

declare global {
  // Interface declaration for the referral widget
  interface Window {
    RF: any;
  }
  interface StrongPasswordCriteria {
    hasEightChars: boolean;
    containsUppercase: boolean;
    containsLowercase: boolean;
    containsNumber: boolean;
    containsSpecialChars: boolean;
    satisfiesCritirea: boolean;
    isStrong: boolean;
  }
  interface String {
    isEmpty(): boolean;
    isNotEmpty(): boolean;
    isNumber(): boolean;
    isValidEmail(): boolean;
    isValidUSPhoneNumber(): boolean;
    formatPhoneNumber(): string;
    replaceSpecialChars(): string;
    getUserNameInitials(): string;
    formatVerificationCode(): string;
    isValidCode(): boolean;
    getStrongPasswordStatus(): StrongPasswordCriteria;
    decodeParam(): string;
    getLastDigits(): string;
    getFirstTwoWords(): string;
    capitalizeWords(): string;
    formatUTCNanoSecondsToString(
      dateFormat: string,
      showTimezone?: boolean
    ): string;
    parsedTimeFromBackend(showTimezone: boolean): string;
    trimCharacterFromBeginAndEnd(character: String): string;
    formatTimeWithTimeZone(timeFormat: string, showTimezone: boolean): string;
  }

  interface Number {
    milliSecondsToNanoSeconds(): number;
  }
}

String.prototype.isNumber = function () {
  if (typeof this !== "string") return false;
  return !isNaN(Number(this));
};

String.prototype.isEmpty = function () {
  return this.trim().length === 0;
};

String.prototype.isNotEmpty = function () {
  return this.trim().length !== 0;
};

String.prototype.isValidEmail = function () {
  const mailRegex = /^([A-Za-z0-9_\-.])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,4})$/;
  return this.trim().isNotEmpty && this.match(mailRegex) != null;
};

String.prototype.isValidUSPhoneNumber = function () {
  const phoneRegex = /^(\()?\d{3}(\))?(-|\s)?\d{3}(-|\s)\d{4}$/;
  return this.trim().isNotEmpty && this.match(phoneRegex) != null;
};

/// This replaces the non-numbers
String.prototype.replaceSpecialChars = function () {
  return this.replace(/[^\d]/g, "");
};

// Formats given text to (123) 456-7890
String.prototype.formatPhoneNumber = function () {
  // Removes all NON numbers
  const phoneNumber = this.replace(/[^\d]/g, "");

  const phoneNumberLength = phoneNumber.length;

  // formats only when 4th digits is entered, so returns the text as it is
  if (phoneNumberLength < 4) return phoneNumber;

  // if phoneNumberLength is greater than 4 and less the 7 we start to return the formatted number
  if (phoneNumberLength < 7) {
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
  }

  // if the phoneNumberLength is greater then seven, we add the last bit of formatting and return it.
  return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
    3,
    6
  )}-${phoneNumber.slice(6, 10)}`;
};

/// This returns the last 4 digits of the given string
String.prototype.getLastDigits = function () {
  return this.substring(this.length - 4);
};

String.prototype.getStrongPasswordStatus = function () {
  const password = this.trim();
  // capital letters from A to Z
  const atLeastOneUppercase = /[A-Z]/g;
  // small letters from a to z
  const atLeastOneLowercase = /[a-z]/g;
  // numbers from 0 to 9
  const atLeastOneNumeric = /[0-9]/g;
  // any of the special characters within the square brackets
  const atLeastOneSpecialChar = /[^A-Za-z 0-9]/g;
  /// password length
  const hasEightChars = password.length >= 8;

  const minimumCriteria = {
    uppercase: password.match(atLeastOneUppercase) != null,
    lowercase: password.match(atLeastOneLowercase) != null,
    number: password.match(atLeastOneNumeric) != null,
    specialChar: password.match(atLeastOneSpecialChar) != null,
  };

  const passwordStrength =
    Object.values(minimumCriteria).filter((value) => value).length >= 3;
  return {
    hasEightChars: hasEightChars,
    containsUppercase: minimumCriteria.uppercase,
    containsLowercase: minimumCriteria.lowercase,
    containsNumber: minimumCriteria.number,
    containsSpecialChars: minimumCriteria.specialChar,
    satisfiesCritirea: passwordStrength,
    isStrong: hasEightChars && passwordStrength,
  };
};

/// Validation of verification code
/// Criteria: The length of the code should not be greater than 4

String.prototype.isValidCode = function () {
  return this.trim().isNotEmpty && this.length === 4;
};

String.prototype.formatVerificationCode = function () {
  // Removes all NON numbers
  const code = this.replace(/[^\d]/g, "");

  const codeLength = code.length;

  /// Returns the code, if the length is less than 4
  /// When the length reaches 4, it restricts the user from entering further and returns the sliced code
  if (codeLength < 4) return code;

  if (codeLength >= 4) {
    return `${code.slice(0, 4)}`;
  }
};

/// This function accepts a fullName and returns its inital characters
String.prototype.getUserNameInitials = function () {
  const splittedUserName = this.split(" ");
  const initialCharacters = splittedUserName.map((character) => {
    return character.charAt(0);
  });
  return initialCharacters.join("");
};

/// This accepts an encoded data and returns the decoded one
String.prototype.decodeParam = function () {
  try {
    const decodedData = window.atob(String(this));
    return decodedData;
  } catch (error) {
    throw error;
  }
};

// Formats a given string to teh given fromat
String.prototype.formatUTCNanoSecondsToString = function (
  dateFormat: string,
  showTimezone = false
): string {
  if (this === "0") {
    return "Not Available";
  }
  const timeInNanoSeconds = parseInt(String(this), 10);
  // Converting the nano seconds time to milli seconds and to browser time
  // Then formatting the date time as required
  const date = moment.utc(timeInNanoSeconds / (1000 * 1000)).local();
  const timezone = showTimezone
    ? ` ${moment().tz(moment.tz.guess()).format("z")}`
    : "";
  return date.format(dateFormat) + timezone;
};

String.prototype.formatTimeWithTimeZone = function (
  timeFormat: string,
  showTimezone: boolean
): string {
  const timezone = showTimezone
    ? ` ${moment().tz(moment.tz.guess()).format("z")}`
    : "";
  return moment.utc(String(this)).local().format(timeFormat) + timezone;
};

Number.prototype.milliSecondsToNanoSeconds = function () {
  return Number(this) * 1000 * 1000;
};

/// This method is used in Vue details screen to parse the time from the dateTime from the backend
String.prototype.parsedTimeFromBackend = function (
  showTimezone: boolean
): string {
  const timeZone = showTimezone
    ? `${moment().tz(moment.tz.guess()).format("z")}`
    : "";
  return moment.parseZone(String(this)).format("hh:mm A") + " " + timeZone;
};

/// This returns the first 2 words from the given string with space in between
/// Used to split the full siteName (to show it in breadcrumbs and url) while navigating to individual vue screen
String.prototype.getFirstTwoWords = function () {
  return this.split(" ").slice(0, 2).join(" ");
};

String.prototype.trimCharacterFromBeginAndEnd = function (character: string) {
  var start = 0;
  var end = this.length;
  if (this.length >= 0) {
    if (this.charAt(start) === character) {
      start++;
    }

    if (this.charAt(end - 1) === character) {
      end--;
    }
  }
  return this.substring(start, end);
};

/// This will capitalize the first letter of each word
String.prototype.capitalizeWords = function () {
  return this.split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(" ");
};

export const groupBy = <T,>(
  array: Array<T>,
  property: (x: T) => string
): { [key: string]: Array<T> } =>
  array.reduce((memo: { [key: string]: Array<T> }, x: T) => {
    if (!memo[property(x)]) {
      memo[property(x)] = [];
    }
    memo[property(x)].push(x);
    return memo;
  }, {});

export const generateRandomString = (stringLength?: number) => {
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let result = "";

  const length = stringLength ?? 10;

  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    result += characters.charAt(randomIndex);
  }

  return result;
};

export interface CountryList {
  [key: string]: { name: string; abbreviation: string }[];
}

// Fetches the list of states and territorry from the given country code.
// Data is fetching from statelist.json file.
export const getStatesAndTerritoryList = (countryCode: string) => {
  const allCountries: CountryList = allCountryAndStateList;
  if (allCountries.hasOwnProperty(countryCode.toUpperCase())) {
    return allCountries[countryCode.toUpperCase()];
  } else {
    return [];
  }
};

export const alphanumericSort = (stringA: string, stringB: string): number => {
  return stringA
    .trim()
    .toLowerCase()
    .localeCompare(stringB.trim().toLowerCase(), "en", { numeric: true });
};

export const parseJWTToken = (token: string) => {
  if (token) {
    var base64Url = token.split(".")[1];
    var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    var jsonPayload = decodeURIComponent(
      window
        .atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );
    return JSON.parse(jsonPayload);
  }
  return {};
};

export const compressBase64Image = async (
  imageData: string,
  maxFileSize: number,
  maxWidthOrHeight: number
) => {
  /// Fetches the image from the imageSource URL using the fetch function
  const fileBlob = await fetch(imageData)
    // array buffer is used to create a new Blob object, representing the image data
    .then((response) => response.arrayBuffer())
    // In this case, we specify the MIME type of the image as "image/png".
    .then((buffer) => new Blob([buffer], { type: "image/png" }));

  /// Handles larger image
  const options = {
    maxSizeMB: maxFileSize,
    useWebWorker: true,
    maxWidthOrHeight: maxWidthOrHeight,
  };
  try {
    const compressedFile = await imageCompression(fileBlob, options);
    return compressedFile;
  } catch (error) {
    console.log("Error caught while compressing file:", error);
  }
};

export const convertNanoToMilliSeconds = (nanoSeconds: number) => {
  return nanoSeconds / 1000 / 1000;
};
