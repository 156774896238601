import { Company, ManageInternalVuerNetwork } from "@ivueit/vue-engine";
import { useAuth } from "context/AuthProvider";
import DashboardContentLayout from "layouts/DashboardContentLayout";

const InternalVuerNetworkLanding = () => {
  const { userData } = useAuth();
  const shortCode = (userData?.companies as Company[])?.filter(
    (item) => item.id === userData.companyId
  )[0]?.shortcode;

  return (
    <DashboardContentLayout needCardBackground={false}>
      <ManageInternalVuerNetwork
        companyId={userData.companyId}
        shortCode={shortCode}
      />
    </DashboardContentLayout>
  );
};

export default InternalVuerNetworkLanding;
