import { defaultType } from "./constants";
import { InvoiceInfo } from "./interfaces";

export enum InvoicePageActionType {
  statusChanged = "STATUS_CHANGED",
  transactionTypeChanged = "TRANSACTION_TYPE_CHANGED",
  startDateChanged = "START_DATE_CHANGED",
  endDateChanged = "END_DATE_CHANGED",
  dateChanged = "DATE_CHANGED",
  clearAllFilterClicked = "CLEAR_ALL_FILTER_CLICKED",
}

export const defaultInvoicePageSummaryState: InvoiceInfo = {
  id: "",
  date: "",
  startDate: null,
  endDate: null,
  dueDate: "",
  accountName: "",
  clientName: "",
  amount: 0,
  memo: "",
  paymentDate: "",
  balance: 0,
  aging: "",
  transactionType: defaultType,
  number: "",
  status: defaultType,
};

export interface InvoicePageAction {
  type: InvoicePageActionType;
  payload: InvoiceInfo;
}

export const invoicesPageSummaryReducer = (
  state: InvoiceInfo,
  action: InvoicePageAction
) => {
  const { type, payload } = action;
  switch (type) {
    case InvoicePageActionType.statusChanged:
      return {
        ...state,
        status: payload.status,
      };
    case InvoicePageActionType.transactionTypeChanged:
      return {
        ...state,
        transactionType: payload.transactionType,
      };
    case InvoicePageActionType.startDateChanged:
      return {
        ...state,
        startDate: payload.startDate,
      };
    case InvoicePageActionType.endDateChanged:
      return {
        ...state,
        endDate: payload.endDate,
      };
    case InvoicePageActionType.clearAllFilterClicked:
      return defaultInvoicePageSummaryState;
    default:
      return state;
  }
};
