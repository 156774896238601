import { ListItem, ListItemIcon, ListItemText } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import React from "react";
import {
  listItemStyle,
  listItemTextStyle,
  secondaryText,
  notifyBadge,
  notificationDurationStyle,
} from "../NotificationStyles";
import { calculateElapsedTime, getNotificationTitle } from "../helpers";
import { CustomIcon } from "./CustomIcon";

export const NotificationDetailItem = ({
  props,
  onItemClick,
}: {
  props: any;
  onItemClick: () => void;
}) => {
  const { vueName, duration, type } = props;

  return (
    <ListItem
      sx={{
        ...listItemStyle,
        borderBottom: "1px solid rgb(233, 234, 237)",
        mt: "12px",
        pb: "12px",
      }}
      onClick={onItemClick}
    >
      <ListItemIcon sx={{ minWidth: "48px", marginTop: "0" }}>
        {<CustomIcon type={type} />}
      </ListItemIcon>
      <ListItemText
        primary={getNotificationTitle(type)}
        sx={{ ...listItemTextStyle, maxWidth: "unset" }}
        secondary={
          <React.Fragment>
            <MDTypography variant="p" fontSize="12px" sx={secondaryText}>
              {vueName}
            </MDTypography>
          </React.Fragment>
        }
      />
      <MDBox
        display="flex"
        flexDirection="column"
        ml="auto"
        alignItems="end"
        justifyContent="space-between"
      >
        <MDBox sx={notifyBadge}></MDBox>

        <MDTypography
          variant="p"
          fontSize="12px"
          opacity="0.64"
          sx={{ ...notificationDurationStyle, marginLeft: "auto", mt: "6px" }}
        >
          {calculateElapsedTime(duration)}
        </MDTypography>
      </MDBox>
    </ListItem>
  );
};
