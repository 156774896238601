import { BatchListDataGrid, BatchParam, Company } from "@ivueit/vue-engine";
import { RoutePath } from "../../../constants";
import { useAuth } from "context/AuthProvider";
import DashboardContentLayout from "layouts/DashboardContentLayout";
import { useNavigate } from "react-router-dom";

export const Batches = () => {
  const { getAvailablePortalsForUser } = useAuth();
  const navigate = useNavigate();
  const availableCompanyList: Company[] = getAvailablePortalsForUser();

  return (
    <DashboardContentLayout needCardBackground={false}>
      <BatchListDataGrid
        navigateToVuesGrid={(stateParam: BatchParam) => {
          navigate(RoutePath.vues, {
            state: stateParam,
          });
        }}
        availableCompanyList={availableCompanyList}
      />
    </DashboardContentLayout>
  );
};
