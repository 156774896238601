import {
  ReactNode,
  useCallback,
  useContext,
  useMemo,
  useReducer,
  useState,
} from "react";
import { VueContext } from "../../../../../context/Context";
import { useLocalStorage } from "hooks/useLocalStorage";
import { LocalStorageKeys } from "../../../../../context/AuthProvider";
import { individualVueState } from "../vue_detail/utils/vue_detail_interface";
import { individualVuePageReducer } from "../vue_detail/utils/reducer";
import { TagItem } from "pages/settings/interfaces/interfaces";

export const VueProvider = ({ children }: { children: ReactNode }) => {
  const [individualVue, dispatchIndividualVueAction] = useReducer(
    individualVuePageReducer,
    individualVueState
  );

  /// Storing the lastly selected vue photo in map section
  const [latestSelectedVueImage, setLatestSelectedVueImage] = useLocalStorage(
    LocalStorageKeys.vuePhoto,
    null
  );

  const [allCompanyMediaTags, setAllCompanyMediaTags] = useState<TagItem[]>([]);

  /// Storing the vue photo
  const storeLatestSelectedVueImage = useCallback(
    async (id: string, data: any) => {
      if (data !== null) {
        setLatestSelectedVueImage({ [id]: data });
      } else {
        setLatestSelectedVueImage(null);
      }
    },
    [setLatestSelectedVueImage]
  );

  /// Storing the company mediaTags
  const storeCompanyMediaTags = useCallback(async (tagsList: TagItem[]) => {
    setAllCompanyMediaTags(tagsList);
  }, []);

  const value = useMemo(
    () => ({
      individualVue,
      allCompanyMediaTags,
      storeCompanyMediaTags,
      dispatchIndividualVueAction,
      storeLatestSelectedVueImage,
      latestSelectedVueImage,
    }),
    [
      allCompanyMediaTags,
      latestSelectedVueImage,
      storeCompanyMediaTags,
      storeLatestSelectedVueImage,
      individualVue,
      dispatchIndividualVueAction,
    ]
  );

  return <VueContext.Provider value={value}>{children}</VueContext.Provider>;
};

export const useVueProvider = () => {
  return useContext(VueContext);
};
