import { Search } from "@mui/icons-material";
import { TextField, InputAdornment } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import { useState } from "react";
import {
  dialogCancelButtonStyle,
  dialogSaveButtonStyle,
} from "../../vues/styles/VueDetailStyles";

interface Props {
  existingName?: string;
  handleCancelClick: () => void;
  handleSaveClick: (name: string) => void;
}

export const AlbumDialogContent = (props: Props) => {
  const [albumName, setAlbumName] = useState<string>(props.existingName);
  const disableSaveButton =
    !albumName.trim() ||
    props.existingName.trim().toLowerCase() === albumName.trim().toLowerCase();

  /// Handles the onChange of the search input
  const handleSearchInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const input = event.target.value;
    /// Max character length should be 150
    if (input.length <= 150) {
      setAlbumName(input);
    } else {
      setAlbumName(input.slice(0, 150));
    }
  };

  const handleSaveButtonClick = () => {
    props.handleSaveClick(albumName);
  };

  return (
    <MDBox p="20px">
      <MDTypography sx={{ fontSize: "14px", fontWeight: "450" }}>
        Enter a unique Album Name
      </MDTypography>
      <TextField
        fullWidth
        placeholder="Add Name"
        value={albumName}
        InputLabelProps={{ shrink: true }}
        onChange={handleSearchInputChange}
        sx={{ py: 1 }}
        InputProps={{}}
      />
      <MDBox py={2} pt={3} display="flex" justifyContent="flex-end">
        <MDButton
          variant="outlined"
          sx={dialogCancelButtonStyle}
          size="small"
          onClick={props.handleCancelClick}
        >
          CANCEL
        </MDButton>
        <MDButton
          variant="gradient"
          color="success"
          disabled={disableSaveButton}
          sx={dialogSaveButtonStyle}
          size="small"
          onClick={handleSaveButtonClick}
        >
          SAVE
        </MDButton>
      </MDBox>
    </MDBox>
  );
};
