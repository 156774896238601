import { useState } from "react";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import FormControl from "@mui/material/FormControl";
import { useAuth } from "context/AuthProvider";
import { Company } from "pages/profile/utils/ProfileInterfaces";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

interface Props {
  title: string;
  onChangeValues: (values: string[]) => void;
  [key: string]: any;
}

function CustomSelect({ title, onChangeValues, ...rest }: Props): JSX.Element {
  const { getAvailablePortalsExcludingReadOnly } = useAuth();
  const availableCompanyList: Company[] = getAvailablePortalsExcludingReadOnly();
  const companyNames = availableCompanyList.map((company) => company.name);
  const selectHeader = `All (${companyNames.length})`;
  /// This holds the values selected by the user from drop-down
  const [selectedValues, setSelectedValues] = useState<string[]>(companyNames);

  /// This handles the checkBox selection
  const isAllSelected =
    companyNames.length > 0 && selectedValues.length === companyNames.length;

  /// Handles the onChange in drop-down box
  const handleChange = (event: SelectChangeEvent<typeof selectedValues>) => {
    const {
      target: { value },
    } = event;

    var selectedItems: string[] = [];
    /// Checkbox selection criteria
    if (value[value.length - 1] === selectHeader) {
      // Handling the select all option
      selectedItems =
        selectedValues.length === companyNames.length ? [] : companyNames;
    } else {
      // On autofill we get a stringified value
      selectedItems = typeof value === "string" ? value.split(",") : value;
    }
    setSelectedValues(selectedItems);
    // Finding the selected company id from the selected value
    const companyIdList = availableCompanyList
      .filter((company) => selectedItems.includes(company.name))
      .map((company) => company.id);

    onChangeValues(companyIdList);
  };

  const getSelectComponentText = (selected: string[]) => {
    const totalSelected = selected.length;

    if (isAllSelected) {
      return selectHeader;
    }

    return totalSelected === 1 ? selected : `${totalSelected} Portals Selected`;
  };

  return (
    <MDBox>
      <MDBox lineHeight={1} mb={0.5}>
        <MDTypography
          variant="caption"
          fontWeight="bold"
          color="dark"
          sx={{ fontSize: "14px" }}
        >
          {title}
        </MDTypography>
      </MDBox>
      <MDBox>
        <FormControl sx={{ display: "flex" }}>
          <Select
            multiple
            value={selectedValues}
            onChange={handleChange}
            input={<OutlinedInput />}
            renderValue={getSelectComponentText}
            MenuProps={MenuProps}
          >
            <MenuItem
              key="select-all"
              value={`All (${companyNames.length})`}
              sx={{
                padding: `6px 0`,
              }}
            >
              <Checkbox checked={isAllSelected} />
              <ListItemText
                primary={selectHeader}
                sx={{
                  "& span": {
                    fontSize: "16px !important",
                    color: "#000",
                    opacity: 0.87,
                  },
                }}
              />
            </MenuItem>
            {/* // Sorted the portal list alphabetically and mapped it */}
            {companyNames.sort().map((name) => (
              <MenuItem
                key={name}
                value={name}
                sx={{
                  padding: `6px 0`,
                  ":focus": {
                    backgroundColor: "unset",
                  },
                  "&.Mui-selected": {
                    backgroundColor: "unset !important",
                    ":hover, :focus": {
                      backgroundColor: "unset !important",
                    },
                  },
                }}
              >
                <Checkbox checked={selectedValues.indexOf(name) > -1} />
                <ListItemText
                  primary={name}
                  sx={{
                    "& span": {
                      fontSize: "16px !important",
                      color: "#000",
                      opacity: 0.87,
                    },
                  }}
                />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </MDBox>
    </MDBox>
  );
}

export default CustomSelect;
