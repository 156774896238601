import { List, ListItem, Link, InputAdornment } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardContentLayout from "layouts/DashboardContentLayout";
import { linkElementStyle } from "../NotificationStyles";
import { ElapsedType } from "../constants";
import MDInput from "components/MDInput";
import { differenceInDays } from "../helpers";
import { NotificationDetailItem } from "./NotificationDetailItem";
import { alphanumericSort } from "utils/helpers/extensions";
import { TEXTFIELD_CHANGE_DELAY } from "../../../constants";
import { useState, useEffect } from "react";
import { Search } from "@mui/icons-material";

export const NotificationDetailScreen = () => {
  const [searchText, setSearchText] = useState<string>("");
  /// This is to avoid the unwanted useEffect invocation unless if the search text hasn't chnaged
  const [searchTextChanged, setSearchTextChanged] = useState<boolean>(false);
  const [notificationsList, setNotificationsList] = useState<any[]>([]);

  const getNotificationsList = (type: ElapsedType) => {
    switch (type) {
      case ElapsedType.newest:
        return notificationsList
          .filter((data) => {
            const { duration } = data;
            const isNewest = differenceInDays(type, duration);
            return isNewest;
          })
          .sort((b, a) => alphanumericSort(a.duration, b.duration))
          .map((data) => (
            <NotificationDetailItem
              props={data}
              onItemClick={handleNotificationItemClick}
            />
          ));
      case ElapsedType.oldest:
        return notificationsList
          .filter((data) => {
            const { duration } = data;
            const isOldest = differenceInDays(type, duration);
            return isOldest;
          })
          .sort((b, a) => alphanumericSort(a.duration, b.duration))
          .map((data) => (
            <NotificationDetailItem
              props={data}
              onItemClick={handleNotificationItemClick}
            />
          ));
      default:
        return [<></>];
    }
  };

  const handleMarkAllAsReadButton = () => {};

  const handleNotificationItemClick = () => {};

  /// Handle search field change
  useEffect(() => {
    if (searchTextChanged) {
      const searchByVueName = (searchText: string) => {
        setSearchText(searchText);
        /// TODO: Need to do search via API when it is ready
        const filteredNotifications = searchText
          ? [].filter((item) =>
              item.vueName.toUpperCase().includes(searchText.toUpperCase())
            )
          : [];
        setNotificationsList(filteredNotifications);
      };
      const delaySearchAction = setTimeout(() => {
        searchByVueName(searchText.trim());
      }, TEXTFIELD_CHANGE_DELAY);
      return () => clearTimeout(delaySearchAction);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText]);

  return (
    <DashboardContentLayout needCardBackground>
      <MDBox pt={5} pr={8.6} pl={8.6}>
        <MDBox>
          <MDTypography variant="h5">Notifications</MDTypography>
        </MDBox>
        <MDBox pt={3.8} pb={3.8} display="flex" alignItems="flex-start">
          <MDBox flexGrow="1" maxWidth="390px">
            <MDInput
              fullWidth
              value={searchText}
              placeholder="Search Vue"
              InputLabelProps={{ shrink: true }}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setSearchTextChanged(true);
                setSearchText(event.target.value);
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Search fontSize="medium" sx={{ color: "#344767" }} />
                  </InputAdornment>
                ),
              }}
            />
          </MDBox>
          <Link
            sx={{ ...linkElementStyle, textTransform: "none" }}
            onClick={handleMarkAllAsReadButton}
            variant="button"
            color="primary"
            fontWeight="regular"
            underline="always"
            ml="auto"
          >
            Mark all as read
          </Link>
        </MDBox>
        <List sx={{ ".MuiListItem-root:last-child": { borderBottom: "none" } }}>
          {getNotificationsList(ElapsedType.newest).length !== 0 && (
            <ListItem
              sx={{
                pb: "12px",
                mb: "4px",
                borderBottom: "1px solid rgb(233, 234, 237)",
                "&:last-child": {
                  borderBottom: "none",
                },
              }}
            >
              <MDTypography variant="h6">Recent</MDTypography>
            </ListItem>
          )}
          {getNotificationsList(ElapsedType.newest)}
        </List>
        <List sx={{ ".MuiListItem-root:last-child": { borderBottom: "none" } }}>
          {getNotificationsList(ElapsedType.oldest).length !== 0 && (
            <ListItem
              sx={{
                pb: "12px",
                mb: "4px",
                borderBottom: "1px solid rgb(233, 234, 237)",
              }}
            >
              <MDTypography variant="h6">More Than a Week Ago</MDTypography>
            </ListItem>
          )}
          {getNotificationsList(ElapsedType.oldest)}
        </List>
      </MDBox>
    </DashboardContentLayout>
  );
};
