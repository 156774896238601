import { convertNanoToMilliSeconds } from "utils/helpers/extensions";
import { InvoiceInfo, InvoicesGridData } from "../utils/interfaces";
import moment from "moment";
import { downloadFileToDevice } from "@ivueit/vue-engine";

export const getInvoiceGridData = (
  invoiceInfo: InvoiceInfo
): InvoicesGridData => {
  return {
    id: invoiceInfo.id,
    date: invoiceInfo.date,
    dueDate: invoiceInfo.dueDate,
    type: "",
    number: "",
    client: invoiceInfo.clientName,
    amount: invoiceInfo.amount,
    status: "",
    paymentDate: invoiceInfo.paymentDate,
    balance: invoiceInfo.balance,
    aging: invoiceInfo.aging,
    memo: invoiceInfo.memo,
  };
};

export const getAgingInDays = (dueDate: string) => {
  const dueDateVal = parseInt(dueDate);
  const dueDateValueInMilliSeconds = convertNanoToMilliSeconds(dueDateVal);
  const days = Math.floor(
    moment().diff(moment(dueDateValueInMilliSeconds), "days")
  );
  return days;
};

export const downloadInvoiceAsPDF = async (
  baseEncodedURL: string,
  invoiceID: string
): Promise<boolean> => {
  const fileName = `Invoice_${invoiceID}`;
  const mimeType = "application/pdf";
  const dataURL = `data:${mimeType};base64,${baseEncodedURL}`;
  try {
    await downloadFileToDevice(dataURL, fileName, mimeType);
    return true;
  } catch (error) {
    return false;
  }
};

export const generateURLFromBase64String = (
  base64String: string,
  mimeType: string
) => {
  const byteCharacters = atob(base64String);
  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  const byteArray = new Uint8Array(byteNumbers);
  const blob = new Blob([byteArray], { type: mimeType });
  const url = URL.createObjectURL(blob);

  return url;
};
