import MDBox from "components/MDBox";

const PdfViewFix = () => {
  return (
    <>
      <MDBox
        sx={{
          content: '""',
          display: "block",
          width: "10px",
          height: "100%",
          position: "absolute",
          top: "0",
          left: "0",
          zIndex: "128",
          background: "white",
        }}
      ></MDBox>
      <MDBox
        sx={{
          content: '""',
          display: "block",
          height: "10px",
          width: "100%",
          position: "absolute",
          top: "0",
          left: "0",
          zIndex: "128",
          background: "white",
        }}
      ></MDBox>
      <MDBox
        sx={{
          content: '""',
          display: "block",
          height: "100%",
          width: "10px",
          position: "absolute",
          right: "12px",
          top: "0",
          zIndex: "128",
          background: "white",
        }}
      ></MDBox>
      <MDBox
        sx={{
          content: '""',
          display: "block",
          height: "10px",
          width: "100%",
          position: "absolute",
          left: "0",
          bottom: "0",
          zIndex: "128",
          background: "white",
        }}
      ></MDBox>
    </>
  );
};

export default PdfViewFix;
