import { Moment } from "moment";
import { DEFAULT_PAGE_SIZE } from "../../../../constants";

export interface InvoiceInfo {
  id: string;
  date: string;
  startDate?: Moment | null;
  endDate?: Moment | null;
  dueDate: string;
  accountName: string;
  clientName: string;
  amount: number;
  memo: string;
  paymentDate: string;
  balance: number;
  aging: string;
  transactionType: string;
  number: string;
  status: string;
}

export interface InvoicesGridData {
  [x: string]: any;
  id: string;
  date: string;
  dueDate: string;
  type: string;
  number: string;
  client: string;
  amount: number;
  status: string;
  memo: string;
  paymentDate: string;
  balance: number;
  aging: string;
}

export interface InvoiceMetaData {
  pageSize: number;
  pageNumber: number;
  totalPages: number;
  totalElements: number;
}

export const defaultInvoicesMetaData = {
  pageSize: DEFAULT_PAGE_SIZE as number,
  pageNumber: 1,
  totalPages: 0,
  totalElements: 0,
};
