import { WebServiceStatus, loaderStyle } from "@ivueit/vue-engine";
import { CardMedia, CircularProgress } from "@mui/material";
import { useState, useEffect } from "react";
import { generatePresignedPhotoFileURL } from "../../vues/services/VueServices";
import MDBox from "components/MDBox";
import placeholderImage from "assets/images/broken_image.png";

interface Props {
  fileID: string;
}

export const PhotoThumbnail = ({ fileID }: Props) => {
  const [photoFileURL, setPhotoFileURL] = useState("");
  const [showLoader, setShowLoader] = useState(false);

  useEffect(() => {
    let imageURL;
    const getPhotoURL = async () => {
      setShowLoader(true);
      if (fileID) {
        const response = await generatePresignedPhotoFileURL(fileID);
        if (response.status === WebServiceStatus.success) {
          const { url } = response.data;
          imageURL = url;
        } else {
          imageURL = placeholderImage;
        }
      } else {
        imageURL = placeholderImage;
      }
      setPhotoFileURL(imageURL);
      setShowLoader(false);
    };
    getPhotoURL();
  }, [fileID]);

  return showLoader ? (
    <MDBox height="300px" position="relative">
      <CircularProgress color="success" sx={loaderStyle} />
    </MDBox>
  ) : (
    <CardMedia
      component="img"
      height="380px"
      width="100%"
      image={photoFileURL}
      onError={() => {
        setPhotoFileURL(placeholderImage);
      }}
      alt="Vue"
      sx={{
        objectFit:
          photoFileURL.toLowerCase() === placeholderImage.toLowerCase()
            ? "cover"
            : "contain",
        width: "100% !important",
      }}
    />
  );
};

export default PhotoThumbnail;
