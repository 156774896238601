export const availableTransactionTypes = ["All", "Invoices", "Payments"];
export const availableStatusTypes = [
  "All",
  "Open",
  "Not sent",
  "Sent",
  "Viewed",
  "Partially paid",
  "Paid",
  "Deposited",
  "Not deposited",
  "Undelivered or Delivery error",
  "Closed",
  "Voided",
  "Overdue",
  "Invoice",
];
export const defaultType = "All";
export const pinnedColumn = ["date"];
