import { Divider } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import { ModalPopUp } from "pages/components/ModalPopUp";
import { SyntheticEvent, useState } from "react";
import { CustomSearchBarWithList } from "./CustomSearchBarWithList";
import { alphanumericSort } from "utils/helpers/extensions";
import { CustomDialogBox } from "@ivueit/vue-engine";

export const ManageColumnContent: React.FC<{
  previouslySelectedItems: string[];
  closePopper: (event: Event | React.SyntheticEvent<Element, Event>) => void;
  manageColumn: (optionsList: string[]) => void;
  availableOptions: string[];
}> = (props) => {
  /// This stores the options selected by the user
  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);

  /// Handles the enability of the "Add" button
  const [shouldEnable, setShouldEnable] = useState<boolean>(false);

  /// State to track the export confirmation dialog
  const [openConfirmationDialog, setOpenConfirmationDialog] =
    useState<boolean>(false);

  /// Opens confirmation dialog
  const openConfirmation = () => {
    setOpenConfirmationDialog(true);
  };

  /// Closes confirmation dialog
  const closeConfirmation = () => {
    setOpenConfirmationDialog(false);
  };

  /// Handles the onClick of the "Add" button
  const manageColumn = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    props.manageColumn(selectedOptions.sort((a, b) => alphanumericSort(a, b)));
    props.closePopper(event);
  };

  /// Handles the onClick of "Cancel" button in the dialog
  const handleCancelClick = (event: Event | SyntheticEvent<Element, Event>) => {
    closeConfirmation();
    props.closePopper(event);
  };

  /// Handles the change in the check box
  const handleCheckboxChange = (optionsList: string[]) => {
    /// List of already visible column's header name
    const visibleColumns = [...props.previouslySelectedItems].sort();
    /// Updated list - Header name of columns to be updated
    const latestSelectionList = [...optionsList].sort();
    /// To enable the "Save" button only if there is a change from the previous list
    if (
      JSON.stringify(visibleColumns) !== JSON.stringify(latestSelectionList)
    ) {
      setShouldEnable(true);
      setSelectedOptions(optionsList);
    } else {
      setShouldEnable(false);
    }
  };

  /// Generates actions for confirmation box
  const confirmationDialogActions = () => {
    return [
      <MDButton
        key={1}
        variant="outlined"
        color="dark"
        size="medium"
        onClick={handleCancelClick}
        sx={{
          borderColor: "#C7CCD0",
          padding: "11px 16px",
          fontSize: "14px",
          fontWeight: "bold",
          "&:hover": { borderColor: "#C7CCD0" },
        }}
      >
        CANCEL
      </MDButton>,
      <MDButton
        key={2}
        variant="gradient"
        color="info"
        size="medium"
        onClick={manageColumn}
        sx={{ padding: "11px 16px", fontSize: "14px", fontWeight: "bold" }}
      >
        SAVE
      </MDButton>,
    ];
  };

  /// Gets the confirmation dialog
  const getConfirmationDialog = () => {
    return (
      <CustomDialogBox
        title="Manage Column"
        width="454px"
        height="200px"
        openDialog={openConfirmationDialog}
      >
        <ModalPopUp
          content={
            "Are you sure you want to update the column selection in the table?"
          }
          closeDialog={closeConfirmation}
          actions={confirmationDialogActions()}
        />
      </CustomDialogBox>
    );
  };

  return (
    <>
      {openConfirmationDialog && getConfirmationDialog()}
      {!openConfirmationDialog && (
        <>
          <MDBox px={2} pt={2}>
            <CustomSearchBarWithList
              shouldShowDeletableChips={false}
              customSelectorList={props.availableOptions}
              handleCheckBoxChange={handleCheckboxChange}
              latestAddedItems={props.previouslySelectedItems}
            />
          </MDBox>
          <Divider
            sx={{
              backgroundColor: "#A8B8D8",
              height: "1x",
              opacity: 0.5,
              m: 0,
              backgroundImage: "none !important",
            }}
          />
          <MDBox px={2} py={2} display="flex" justifyContent="flex-end">
            <MDButton
              variant="outlined"
              sx={{
                ml: 2,
                fontSize: "14px",
                fontWeight: "bold",
                color: "#344767",
                borderColor: "dark.main",
                "&:hover": {
                  backgroundColor: "white.main",
                  color: "dark.main",
                  borderColor: "dark.main",
                },
              }}
              size="small"
              onClick={props.closePopper}
            >
              CANCEL
            </MDButton>
            <MDButton
              variant="gradient"
              color="success"
              sx={{
                ml: 2,
                fontSize: "14px",
                fontWeight: "bold",
                color: "white.main",
                backgroundColor: "success.main",
                "&:hover": { backgroundColor: "success.main" },
              }}
              size="small"
              disabled={!shouldEnable}
              onClick={() => {
                openConfirmation();
              }}
            >
              SAVE
            </MDButton>
          </MDBox>
        </>
      )}
    </>
  );
};
