import {
  mdiTag,
  mdiAccountEdit,
  mdiEyeOff,
  mdiAlertOutline,
  mdiEye,
  mdiTrayArrowDown,
} from "@mdi/js";
import { Grid, Tooltip } from "@mui/material";
import MDBox from "components/MDBox";
import { styled } from "@mui/material/styles";
import { escalatedButtonStyle } from "../../styles/VueDetailStyles";
import { tooltipStyles } from "../../vue-grid/components/VueActionBar";
import TooltipWrapper from "pages/components/TooltipWrapper";
import { READ_ONLY_DISABLED_MESSAGE } from "./../../../../../../constants";
import { ButtonWithIcon } from "@ivueit/vue-engine";

/// Styles applied to the "ButtonWithIcon"
export const StyledButtonWithIcon = styled(ButtonWithIcon)(({ theme }) => ({
  paddingLeft: "14px",
  paddingRight: "14px",
  "&:disabled": {
    color: "#C7CCD0",
    borderColor: "#C7CCD0",
  },
  svg: {
    width: "18px !important",
    height: "18px !important",
  },
  cursor: "pointer",
}));

export enum PhotoActionButtonType {
  tag,
  edit,
  escalate,
  deescalate,
  hide,
  download,
}

interface Props {
  hasEscalated: boolean;
  hidePhoto: boolean;
  onButtonClick: (type: PhotoActionButtonType) => void;
  isStepTypeVideo: boolean;
  isReadOnly: boolean;
}

export const MediaActionBar = ({
  hasEscalated,
  hidePhoto,
  onButtonClick,
  isStepTypeVideo,
  isReadOnly,
}: Props) => {
  return (
    <MDBox display="flex" justifyContent="space-between" mt={1.5} pb={1.5}>
      <Grid container spacing={1}>
        <Grid item>
          <TooltipWrapper
            title={READ_ONLY_DISABLED_MESSAGE}
            disableHoverListener={!isReadOnly}
          >
            <StyledButtonWithIcon
              iconPath={mdiTag}
              onClick={() => {
                onButtonClick(PhotoActionButtonType.tag);
              }}
              disabled={isReadOnly}
            >
              TAG
            </StyledButtonWithIcon>
          </TooltipWrapper>
        </Grid>
        {!isStepTypeVideo && (
          <Grid item>
            <TooltipWrapper
              title={READ_ONLY_DISABLED_MESSAGE}
              disableHoverListener={!isReadOnly}
            >
              <StyledButtonWithIcon
                iconPath={mdiAccountEdit}
                onClick={() => {
                  onButtonClick(PhotoActionButtonType.edit);
                }}
                disabled={isReadOnly}
              >
                EDIT
              </StyledButtonWithIcon>
            </TooltipWrapper>
          </Grid>
        )}
        <Grid item>
          <TooltipWrapper
            title={READ_ONLY_DISABLED_MESSAGE}
            disableHoverListener={!isReadOnly}
          >
            <StyledButtonWithIcon
              iconPath={mdiAlertOutline}
              sx={hasEscalated ? { ...escalatedButtonStyle } : null}
              onClick={() => {
                onButtonClick(
                  hasEscalated
                    ? PhotoActionButtonType.deescalate
                    : PhotoActionButtonType.escalate
                );
              }}
              disabled={isReadOnly}
            >
              {hasEscalated ? "ESCALATED" : "ESCALATE"}
            </StyledButtonWithIcon>
          </TooltipWrapper>
        </Grid>
        <Grid item>
          <TooltipWrapper
            title={READ_ONLY_DISABLED_MESSAGE}
            disableHoverListener={!isReadOnly}
          >
            <StyledButtonWithIcon
              iconPath={hidePhoto ? mdiEyeOff : mdiEye}
              onClick={() => {
                onButtonClick(PhotoActionButtonType.hide);
              }}
              disabled={isReadOnly}
            >
              {hidePhoto ? "SHOW" : "HIDE"}
            </StyledButtonWithIcon>
          </TooltipWrapper>
        </Grid>
        <Grid item>
          <Tooltip
            title={`Hidden ${
              isStepTypeVideo ? "video" : "photos"
            } cannot be downloaded`}
            disableHoverListener={!hidePhoto}
            placement="right"
            componentsProps={{
              tooltip: {
                sx: { ...tooltipStyles },
              },
            }}
          >
            <span>
              <StyledButtonWithIcon
                disabled={hidePhoto}
                iconPath={mdiTrayArrowDown}
                onClick={() => {
                  onButtonClick(PhotoActionButtonType.download);
                }}
              >
                DOWNLOAD
              </StyledButtonWithIcon>
            </span>
          </Tooltip>
        </Grid>
      </Grid>
    </MDBox>
  );
};
