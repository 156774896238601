import { RoutePath } from "./../../constants";
import { useEffect } from "react";

interface Props {
  firstName: string;
  email: string;
  pathName: string;
}

// The referral widgets will be displayed only on these paths
const supportedPathNames = [
  RoutePath.profile,
  RoutePath.invoices,
  RoutePath.settings,
  RoutePath.customersupport,
];

const ReferralWidget = ({ firstName, email, pathName }: Props): any => {
  useEffect(() => {
    // Removes the referral components
    const removeReferralComponent = () => {
      // Launcher widget automatically added by the widget
      // If we didn't remove it, the widget will stay on top.
      const rfLauncher = document.getElementById("rf-launcher");
      // Element we adds programmatcially
      const rfScript = document.getElementById("rf-script");
      if (rfLauncher) {
        document.body.removeChild(rfLauncher);
      }
      if (rfScript) {
        document.body.removeChild(rfScript);
      }
    };

    // Set up the user data in the global RF object
    window.RF = {
      user: {
        first_name: firstName.trim().isNotEmpty() ? firstName : "iVueit User",
        email: email,
      },
    };

    // Create the script element for the referral widget
    const script = document.createElement("script");
    script.id = "rf-script";
    script.src =
      "https://referral-factory.com/assets/js/widget.js?code=UXsGEC&title=$500+Per+Referral&color=000000&color_light=323232";
    script.async = true;
    // checks whether we have to display the widget on this path
    const shouldShowRefer = supportedPathNames.includes(pathName as RoutePath);
    
    if (shouldShowRefer) {
      // Append the script to the document body
      document.body.appendChild(script);
    } else {
      removeReferralComponent();
    }

    // Cleanup function to remove the script when the component is unmounted
    return () => {
      removeReferralComponent();
    };
  }, [firstName, email, pathName]);

  return null; // This component does not render anything itself
};

export default ReferralWidget;
