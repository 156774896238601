import MDBox from "components/MDBox";
import DashboardContentLayout from "layouts/DashboardContentLayout";
import DialogSectionTitle from "pages/my-account/manage-users/components/DialogSectionTitle";
import PortalListComponent from "./components/PortalListComponent";
import { useEffect, useState } from "react";
import AddTagDialogComponent from "./components/AddTagDialogContent";
import ManageTagSection from "./components/ManageTagSection";
import { ConfirmationDialog } from "pages/dashboard/home/site-lists/components/ConfirmationDialog";
import GenerateAPIKeyDialog from "./components/GenerateAPIKeyDialog";
import {
  AddMediaTag,
  AddVueTag,
  DeleteMediaTag,
  DeleteVueTag,
  EditMediaTag,
  EditVueTag,
  getMediaTags,
  getVueTags,
} from "./services/settingsService";
import { WebServiceStatus } from "utils/services/AppUrls";
import { TagItem } from "./interfaces/interfaces";
import { CustomIndicator } from "pages/components/CustomIndicator";
import { alphanumericSort } from "utils/helpers/extensions";
import CustomSnackbar, {
  CustomSnackbarContent,
} from "pages/components/CustomSnackbar";
import { CustomDialogBox } from "@ivueit/vue-engine";

enum TagType {
  vues,
  photos,
}

const Settings = () => {
  const [showLoader, setShowLoader] = useState<boolean>(false);
  // Whether to display add tag dialog or not. When NULL no dialogs will be displayed
  const [tagDialogType, setTagDialogType] = useState<TagType | null>(null);
  const [deleteConfirmationType, setDeleteConfirmationType] =
    useState<TagType | null>(null);
  // Vue Tags
  const [vueTags, setVueTags] = useState<TagItem[]>([]);
  // Photo tags
  const [photoTags, setPhotoTags] = useState<TagItem[]>([]);
  // Tag selected for editing
  const [selectedTag, setSelectedTag] = useState<TagItem | null>(null);
  // Error message to be shown in snackbar
  const [snackbarContent, setSnackbarContent] =
    useState<CustomSnackbarContent | null>(null);
  const [companyIdForAPIKey, setCompanyIdForAPIKey] = useState("");

  // *************** TAGS *************** //
  const getVueTagsFromServer = async () => {
    setShowLoader(true);
    const response = await getVueTags();
    if (response.status === WebServiceStatus.success) {
      const tagList = response.data.tags as TagItem[];
      const tagSorted = tagList.sort((a, b) =>
        alphanumericSort(a.name, b.name)
      );
      setVueTags(tagSorted);
    }
    setShowLoader(false);
  };

  const getMediaTagsFromServer = async () => {
    setShowLoader(true);
    const response = await getMediaTags();
    if (response.status === WebServiceStatus.success) {
      const tagList = response.data.tags as TagItem[];
      const tagSorted = tagList.sort((a, b) =>
        alphanumericSort(a.name, b.name)
      );
      setPhotoTags(tagSorted);
    }
    setShowLoader(false);
  };

  useEffect(() => {
    getVueTagsFromServer();
    getMediaTagsFromServer();
  }, []);

  const addVueTagsToServer = async (name: string) => {
    setShowLoader(true);
    const response = await AddVueTag(name);
    if (response.status === WebServiceStatus.success) {
      getVueTagsFromServer();
    } else {
      setShowLoader(false);
      setSnackbarContent({
        title: "Attention!",
        message: response.error,
        isError: true,
      });
    }
  };

  const addMediaTagsToServer = async (name: string) => {
    setShowLoader(true);
    const response = await AddMediaTag(name);
    if (response.status === WebServiceStatus.success) {
      getMediaTagsFromServer();
    } else {
      setShowLoader(false);
      setSnackbarContent({
        title: "Attention!",
        message: response.error,
        isError: true,
      });
    }
  };

  const deleteVueTagsFromServer = async (tagSelected: TagItem) => {
    setShowLoader(true);
    const response = await DeleteVueTag(tagSelected.id);
    if (response.status === WebServiceStatus.success) {
      getVueTagsFromServer();
    } else {
      setShowLoader(false);
      setSnackbarContent({
        title: "Attention!",
        message: response.error,
        isError: true,
      });
    }
  };

  const deleteMediaTagsFromServer = async (tagSelected: TagItem) => {
    setShowLoader(true);
    const response = await DeleteMediaTag(tagSelected.id);
    if (response.status === WebServiceStatus.success) {
      getMediaTagsFromServer();
    } else {
      setShowLoader(false);
      setSnackbarContent({
        title: "Attention!",
        message: response.error,
        isError: true,
      });
    }
  };

  const editVueTagsToServer = async (tagSelected: TagItem, newTag: string) => {
    setShowLoader(true);
    const response = await EditVueTag(tagSelected.id, newTag);
    if (response.status === WebServiceStatus.success) {
      getVueTagsFromServer();
    } else {
      setShowLoader(false);
      setSnackbarContent({
        title: "Attention!",
        message: response.error,
        isError: true,
      });
    }
  };

  const editMediaTagsToServer = async (
    tagSelected: TagItem,
    newTag: string
  ) => {
    setShowLoader(true);
    const response = await EditMediaTag(tagSelected.id, newTag);
    if (response.status === WebServiceStatus.success) {
      getMediaTagsFromServer();
    } else {
      setShowLoader(false);
      setSnackbarContent({
        title: "Attention!",
        message: response.error,
        isError: true,
      });
    }
  };

  const getTagDialog = () => {
    var title = !selectedTag ? "ADD TAG" : "EDIT TAG";
    const availableTags = tagDialogType === TagType.vues ? vueTags : photoTags;
    return (
      <CustomDialogBox
        title={title.toUpperCase()}
        width="450px"
        openDialog={tagDialogType !== null}
      >
        <AddTagDialogComponent
          tagName={selectedTag ? selectedTag.name : ""}
          onClickCancel={() => {
            setSelectedTag(null);
            setTagDialogType(null);
          }}
          onClickSave={(newTag) => {
            const tagType = tagDialogType;
            updateTagList(newTag, tagType);
          }}
          availableTags={availableTags}
        />
      </CustomDialogBox>
    );
  };

  const getAPIKeyGenerator = () => {
    return (
      <CustomDialogBox
        title={"API Key"}
        width="844px"
        openDialog={companyIdForAPIKey.isNotEmpty()}
      >
        <GenerateAPIKeyDialog
          closeDialog={() => {
            setCompanyIdForAPIKey("");
          }}
          companyId={companyIdForAPIKey}
        />
      </CustomDialogBox>
    );
  };

  const updateTagList = (value: string, type: TagType) => {
    switch (type) {
      case TagType.vues:
        if (selectedTag) {
          editVueTagsToServer(selectedTag, value);
        } else {
          addVueTagsToServer(value);
        }
        break;
      case TagType.photos:
        if (selectedTag) {
          editMediaTagsToServer(selectedTag, value);
        } else {
          addMediaTagsToServer(value);
        }
        break;
      default:
        break;
    }
    setSelectedTag(null);
    setTagDialogType(null);
  };

  /// Handles the active/inactive feature
  const getDeleteTagConfirmation = () => {
    if (!selectedTag) {
      return <></>;
    }

    return (
      <CustomDialogBox
        title={"Delete Tag".toUpperCase()}
        width="450px"
        openDialog={deleteConfirmationType !== null}
      >
        <ConfirmationDialog
          message={"Are you sure you want to delete this tag?"}
          buttonName={"Yes"}
          onClose={() => {
            setDeleteConfirmationType(null);
            setSelectedTag(null);
          }}
          onClick={deleteTag}
        />
      </CustomDialogBox>
    );
  };

  const deleteTag = async () => {
    if (deleteConfirmationType === TagType.vues) {
      await deleteVueTagsFromServer(selectedTag);
    } else if (deleteConfirmationType === TagType.photos) {
      await deleteMediaTagsFromServer(selectedTag);
    }
    setSelectedTag(null);
    setDeleteConfirmationType(null);
  };

  return (
    <DashboardContentLayout>
      {deleteConfirmationType !== null && getDeleteTagConfirmation()}
      {setTagDialogType !== null && getTagDialog()}
      {companyIdForAPIKey && getAPIKeyGenerator()}
      {showLoader && <CustomIndicator />}
      <MDBox padding={5}>
        <ManageTagSection
          title={"MANAGE VUE TAGS"}
          message={
            "Add, edit or delete lead tags. Keep in mind that all changes are system wide."
          }
          onClickAddTag={() => setTagDialogType(TagType.vues)}
          tags={vueTags}
          onEditClick={(tag) => {
            setSelectedTag(tag);
            setTagDialogType(TagType.vues);
          }}
          onDeleteClick={(tag) => {
            setSelectedTag(tag);
            setDeleteConfirmationType(TagType.vues);
          }}
        />
        <MDBox>
          <ManageTagSection
            title={"MANAGE PHOTO TAGS"}
            message={
              "Add, edit or delete photo tags. Keep in mind that all changes are system wide."
            }
            onClickAddTag={() => setTagDialogType(TagType.photos)}
            tags={photoTags}
            onEditClick={(tag) => {
              setSelectedTag(tag);
              setTagDialogType(TagType.photos);
            }}
            onDeleteClick={(tag) => {
              setSelectedTag(tag);
              setDeleteConfirmationType(TagType.photos);
            }}
          />
        </MDBox>
        <MDBox>
          <DialogSectionTitle title="MANAGE PORTALS" fontSize="20px" />
          <PortalListComponent
            onGenerateAPIKey={(companyId: string) => {
              setCompanyIdForAPIKey(companyId);
            }}
          />
        </MDBox>
      </MDBox>
      <CustomSnackbar
        snackbarContent={snackbarContent}
        onClose={() => {
          setSnackbarContent(null);
        }}
      />
    </DashboardContentLayout>
  );
};
export default Settings;
