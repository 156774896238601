import styled from "@emotion/styled";
import {
  mdiFileExport,
  mdiArchive,
  mdiArchiveCancel,
} from "@mdi/js";
import { Grid, Tooltip } from "@mui/material";
import { MDBox } from "../../vues/vue-grid/helpers/imports";
import { tooltipStyles } from "../styles/site_list_style";
import { ButtonWithIcon } from "@ivueit/vue-engine";

/// Styles applied to the "ButtonWithIcon" explicitly for the actionButtons in the topBar
export const StyledButtonWithIcon = styled(ButtonWithIcon)(({ theme }) => ({
  paddingLeft: "16px",
  paddingRight: "16px",
  "&:disabled": {
    color: "#C7CCD0",
    borderColor: "#C7CCD0",
  },
  svg: {
    width: "18px !important",
    height: "18px !important",
  },
}));

export enum SiteListActionBarButtonType {
  exportSiteList,
  archive,
  unarchive,
  delete,
  none,
}

interface Props {
  onButtonClick: (type: SiteListActionBarButtonType) => void;
  disableArchiveButton?: boolean;
  disableUnArchiveButton?: boolean;
}

export const SiteListActionBar = ({
  onButtonClick,
  disableArchiveButton,
  disableUnArchiveButton,
}: Props) => {
  return (
    <MDBox display="flex" justifyContent="space-between">
      <Grid container spacing={1}>
        <Grid item>
          <StyledButtonWithIcon
            iconPath={mdiFileExport}
            onClick={() => {
              onButtonClick(SiteListActionBarButtonType.exportSiteList);
            }}
          >
            Export
          </StyledButtonWithIcon>
        </Grid>
        <Grid item>
          <Tooltip
            title="One or more of the selected sites are already archived."
            arrow
            placement="right"
            componentsProps={{
              tooltip: {
                sx: tooltipStyles,
              },
            }}
            disableHoverListener={!disableArchiveButton}
          >
            <span>
              <StyledButtonWithIcon
                iconPath={mdiArchive}
                disabled={disableArchiveButton}
                onClick={() => {
                  onButtonClick(SiteListActionBarButtonType.archive);
                }}
              >
                Archive
              </StyledButtonWithIcon>
            </span>
          </Tooltip>
        </Grid>
        <Grid item>
          <Tooltip
            title="One or more of the selected sites are active."
            arrow
            placement="right"
            componentsProps={{
              tooltip: {
                sx: tooltipStyles,
              },
            }}
            disableHoverListener={!disableUnArchiveButton}
          >
            <span>
              <StyledButtonWithIcon
                iconPath={mdiArchiveCancel}
                disabled={disableUnArchiveButton}
                onClick={() => {
                  onButtonClick(SiteListActionBarButtonType.unarchive);
                }}
              >
                Unarchive
              </StyledButtonWithIcon>
            </span>
          </Tooltip>
        </Grid>
      </Grid>
    </MDBox>
  );
};
