export const gridCardStyle = {
  minHeight: `calc(100vh - 190px)`,
  height: `calc(100vh - 190px)`,
  justifyContent: "flex-start",
  position: "relative",
  mt: "20px",
};

export const gridContentStyle = {
  display: "flex",
  alignItems: "center",
  minWidth: "0",
};

export const gridContentWithEllipsis = {
  maxWidth: "124px",
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
};
