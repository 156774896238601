import { DialogContent, DialogActions } from "@mui/material";
import MDButton from "components/MDButton";
import VersionListItem from "./VersionListItem";
import iVueItlogo from "assets/images/logo7.png";
import MDAvatar from "components/MDAvatar";
import MDBox from "components/MDBox";
import { useEffect, useState } from "react";
import {
  getAppServiceVersion,
  getLoginAppServiceVersion,
} from "pages/dashboard/home/common/services/services";
import { WebServiceStatus } from "utils/services/AppUrls";
import { AppVersionInfo } from "pages/dashboard/home/common/types/types";

interface Props {
  closeDialog: () => void;
}

const InfoDialogContent = (props: Props) => {
  const [appVersion, setAppVersion] = useState<AppVersionInfo | null>(null);
  const [loginAppVersion, setLoginAppVersion] = useState<AppVersionInfo | null>(
    null
  );

  useEffect(() => {
    const fetchAppVersion = async () => {
      const response = await getAppServiceVersion();
      if (response.status === WebServiceStatus.success) {
        if ((response.data as AppVersionInfo).version) {
          const appVersionData = response.data as AppVersionInfo;
          setAppVersion(appVersionData);
        } else {
          setAppVersion(null);
        }
      } else {
        setAppVersion(null);
      }
    };
    const fetchLoginAppVersion = async () => {
      const response = await getLoginAppServiceVersion();
      if (response.status === WebServiceStatus.success) {
        if ((response.data as AppVersionInfo).version) {
          const loginAppData = response.data as AppVersionInfo;
          setLoginAppVersion(loginAppData);
        } else {
          setLoginAppVersion(null);
        }
      } else {
        setLoginAppVersion(null);
      }
    };

    fetchAppVersion();
    fetchLoginAppVersion();
  }, []);

  const getVersionString = (versionInfo: AppVersionInfo): string => {
    let versionString = "";
    if (versionInfo.version) {
      versionString += versionInfo.version;
    }
    if (appVersion.migration) {
      versionString += `, Migration ${appVersion.migration.version}`;
    }
    return versionString;
  };

  return (
    <>
      <DialogContent sx={{ padding: "32px 29px !important" }}>
        <MDBox
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <MDBox mb={2}>
            <MDAvatar
              src={iVueItlogo}
              alt="Profile Image"
              size="lg"
              sx={{ img: { objectFit: "scale-down" } }}
            />
          </MDBox>
          <VersionListItem
            title={"Beta"}
            version={`${process.env.REACT_APP_VERSION}`}
          />
          {appVersion && (
            <VersionListItem
              title={appVersion.name ?? ""}
              version={getVersionString(appVersion)}
            />
          )}
          {loginAppVersion && (
            <VersionListItem
              title={loginAppVersion.name ?? ""}
              version={getVersionString(loginAppVersion)}
            />
          )}
        </MDBox>
      </DialogContent>
      <DialogActions>
        <>
          <MDButton
            key={1}
            variant="outlined"
            color="dark"
            size="medium"
            onClick={() => {
              props.closeDialog();
            }}
            sx={{
              borderColor: "#C7CCD0",
              padding: "11px 16px",
              fontSize: "14px",
              fontWeight: "bold",
              "&:hover": { borderColor: "#C7CCD0" },
            }}
          >
            CLOSE
          </MDButton>
        </>
      </DialogActions>
    </>
  );
};

export default InfoDialogContent;
