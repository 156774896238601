import Grid from "@mui/material/Grid/Grid";
import {
  IndividualVue,
  PhotoFileInfo,
  SurveyStep,
  SurveyStepState,
  VideoFileInfo,
} from "../utils/vue_detail_interface";
import { VueMediaSection } from "./VueMediaSection";
import MDTypography from "components/MDTypography";
import { SurveyStepType, getSurveyStepType } from "../types/types";
import { VueSignature } from "./VueSignature";
import { useEffect, useState } from "react";
import {
  downloadPhotoSectionFile,
  getVideoFileInfo,
} from "pages/dashboard/home/vues/services/VueServices";
import { CircularProgress } from "@mui/material";
import { loaderStyle } from "../../styles/VueDetailStyles";
import { IndividualVueActionType } from "../utils/reducer";
import { useVueProvider } from "../../context/VueProvider";
import { SurveySummary } from "@ivueit/vue-engine";
import { getVueStatus } from "../../vue-grid/utils/enums";
import MDBox from "components/MDBox";
import { WebServiceStatus } from "utils/services/AppUrls";
import {
  alphanumericSort,
  generateRandomString,
} from "utils/helpers/extensions";

interface Props {
  submissionId: string;
  surveyStep: SurveyStep;
  stepState: SurveyStepState;
  isReadOnly: boolean;
}

export const QuestionAnswerComponent = ({
  surveyStep,
  stepState,
  isReadOnly,
}: Props) => {
  // Fetches the step type
  const stepType = getSurveyStepType(surveyStep.stepType);
  const serialNumber: number = parseInt(surveyStep.index) + 1;
  const [loader, setLoader] = useState<boolean>(false);
  const {
    individualVue,
    dispatchIndividualVueAction,
  }: { individualVue: IndividualVue; dispatchIndividualVueAction: Function } =
    useVueProvider();
  const vueStatus = getVueStatus(individualVue.status);

  const getPhotoSection = (photo: PhotoFileInfo) => {
    return <VueMediaSection photoFileInfo={photo} isReadOnly={isReadOnly} />;
  };

  /// Used for the answer component
  const getTypography = (label: string) => {
    return (
      <MDTypography
        key={`${label}-${generateRandomString(5)}`}
        sx={{
          fontSize: "16px",
          fontWeight: "600",
        }}
      >
        {label}
      </MDTypography>
    );
  };

  useEffect(() => {
    const fetchStepStateWithPhoto = async () => {
      setLoader(true);
      const value = JSON.parse(stepState.value) as { photoIds: string[] };
      const photoIds = value.photoIds;
      const responses = await Promise.all(
        photoIds.map(async (photoId) => {
          const response = await downloadPhotoSectionFile(photoId);
          const file = response.data as PhotoFileInfo;
          return file;
        })
      );
      const availablePhotos: PhotoFileInfo[] = responses
        .filter((file) => !file.adminHide)
        .sort((a, b) => alphanumericSort(a.capturedAt, b.capturedAt))
        .map((file, index) => {
          const updatedFileInfo = {
            ...file,
            stepStateId: stepState.id,
            serialNumber: `${serialNumber}-${index + 1}`,
          };
          return updatedFileInfo;
        });
      dispatchIndividualVueAction({
        type: IndividualVueActionType.loadPhotoFiles,
        payload: {
          ...individualVue,
          photos: availablePhotos,
        },
      });
    };

    const fetchStepStateWithMeasurement = async () => {
      const value = JSON.parse(stepState.value) as {
        photoId: string;
        formattedMeasurement: string;
      };
      const { photoId } = value;
      if (!photoId) return;
      const response = await downloadPhotoSectionFile(photoId);
      if (response.status === WebServiceStatus.success) {
        const measurementFile = response.data as PhotoFileInfo;
        if (measurementFile.adminHide) return;
        const photosList = [...individualVue.photos, measurementFile]
          .sort((a, b) => alphanumericSort(a.capturedAt, b.capturedAt))
          .map((file, index) => {
            const measurementPhoto = {
              ...file,
              stepStateId: stepState.id,
              serialNumber: `${serialNumber}-${index + 1}`,
            };
            return measurementPhoto;
          });
        dispatchIndividualVueAction({
          type: IndividualVueActionType.loadPhotoFiles,
          payload: {
            ...individualVue,
            photos: photosList,
          },
        });
      } else {
        // TODO: SHOW ERROR
      }
    };

    const fetchStepStateWithVideo = async () => {
      setLoader(true);
      const value = JSON.parse(stepState.value) as {
        videoFileId: string;
        thumbnailPhotoFileId: string;
      };
      const { videoFileId, thumbnailPhotoFileId } = value;
      const response = await getVideoFileInfo(videoFileId);
      if (response.status === WebServiceStatus.success) {
        const file = response.data as VideoFileInfo;
        // Excluding admin hidden videos
        const videoFiles = [file]
          .filter((item) => !item.adminHide)
          .sort((a, b) => alphanumericSort(a.capturedAt, b.capturedAt))
          .map((file, index) => {
            const video = {
              ...file,
              serialNumber: `${serialNumber}-${index + 1}`,
              stepStateId: stepState.id,
              thumbnailFileId: thumbnailPhotoFileId,
            };
            return video;
          });
        dispatchIndividualVueAction({
          type: IndividualVueActionType.loadVideoFile,
          payload: {
            ...individualVue,
            videos: videoFiles,
          },
        });
      } else {
        // TODO: SHOW ERROR
      }
    };

    const fetchStepStateValues = async () => {
      if (stepState.value && vueStatus.toUpperCase() !== "IN REVIEW") {
        if (stepType === SurveyStepType.photo) {
          // Downloading step state with photo types
          await fetchStepStateWithPhoto();
        }
        if (stepType === SurveyStepType.measurement) {
          // Downloading step state with measurement type
          await fetchStepStateWithMeasurement();
        }
        if (stepType === SurveyStepType.video) {
          // Downloading step state with video type
          await fetchStepStateWithVideo();
        }
        setLoader(false);
      }
    };
    fetchStepStateValues();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stepState, stepType]);

  const getAnswerForMeasurementType = (stepState: any) => {
    const value = JSON.parse(stepState.value) as {
      photoId: string;
      formattedMeasurement: string;
    };
    const { photoId, formattedMeasurement } = value;
    if (photoId) {
      const photoFile = individualVue.photos.find(
        (item) => item.id === photoId
      );
      const element = (
        <>
          {getTypography(formattedMeasurement)}
          {photoFile && (
            <>
              <MDBox p={1}></MDBox>
              {getPhotoSection(photoFile)}
            </>
          )}
        </>
      );
      return element;
    } else {
      return getTypography(stepState.value);
    }
  };

  /// Generates answer component based on the answer props
  const getAnswerComponent = () => {
    var elementArray: JSX.Element[] = [];
    if (
      stepState.value &&
      stepState.value.isNotEmpty() &&
      vueStatus.toUpperCase() !== "IN REVIEW"
    ) {
      switch (stepType) {
        case SurveyStepType.photo:
          {
            let elements: JSX.Element[] = [];
            individualVue.photos.forEach((photo, index) => {
              const file = {
                ...photo,
                ...{ serialNumber: `${serialNumber}-${index + 1}` },
              };
              const photoFile = individualVue.photos.find(
                (item) =>
                  item.canonicalId === file.canonicalId &&
                  stepState.id === photo.stepStateId
              );
              if (photoFile) {
                elements.push(getPhotoSection(photoFile));
              }
            });
            elementArray = [...elementArray, ...elements];
          }
          break;
        case SurveyStepType.signature:
          {
            const value = JSON.parse(stepState.value) as {
              signatureImageId: string;
            };
            const signature = value.signatureImageId;
            const element = <VueSignature photoId={signature}></VueSignature>;
            elementArray.push(element);
          }
          break;
        case SurveyStepType.video:
          {
            let elements: JSX.Element[] = [];
            individualVue.videos.forEach((video, index) => {
              const videoFile = {
                ...video,
                serialNumber: `${serialNumber}-${index + 1}`,
              };
              if (stepState.id === videoFile.stepStateId) {
                const element = (
                  <VueMediaSection
                    isStepTypeVideo={true}
                    videoFileInfo={videoFile}
                    isReadOnly={isReadOnly}
                  />
                );
                elements.push(element);
              }
            });
            elementArray = [...elementArray, ...elements];
          }
          break;
        case SurveyStepType.multiSelect:
          // Handling multi select case
          {
            const value = JSON.parse(stepState.value) as {
              selectedAnswers: string[];
            };
            const answers = value.selectedAnswers;
            const elements = [
              <MDBox display="flex" flexDirection="column">
                {answers.map((selectedAnswer) => getTypography(selectedAnswer))}
              </MDBox>,
            ];
            elementArray = [...elementArray, ...elements];
          }
          break;
        case SurveyStepType.measurement:
          {
            const element = getAnswerForMeasurementType(stepState);
            elementArray.push(element);
          }
          break;
        default:
          // All other options uses text
          elementArray.push(getTypography(stepState.value));
          break;
      }

      return (
        <>
          {loader && <CircularProgress color="success" sx={loaderStyle} />}
          <Grid container spacing={elementArray.length > 0 ? 4 : 0}>
            {elementArray.map((element, index) => (
              <Grid key={index} item xs={12} sm={6} md={6} lg={6} xl={6}>
                {element}
              </Grid>
            ))}
          </Grid>
        </>
      );
    }
    return <></>;
  };

  return (
    <SurveySummary
      header={`${serialNumber}. ${surveyStep.description.replaceAll('"', "")}`}
      body={
        stepType === SurveyStepType.instructions ? null : getAnswerComponent()
      }
    />
  );
};
