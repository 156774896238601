import { useRef, useState, useEffect } from "react";

// react-router-dom components
import { Link, useLocation, useNavigate } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import CoverLayout from "layouts/CoverLayout";

// Images
import bgImage from "assets/images/authentication-cover.jpeg";
import { IconButton, InputAdornment } from "@mui/material";
import {
  Visibility,
  VisibilityOff,
  InfoOutlined,
  CheckCircleOutlineRounded,
} from "@mui/icons-material";
import "utils/helpers/extensions";
import MessageAlert from "../components/MessageAlert";
import { RoutePath } from "./../../../constants";
import { useAuth } from "context/AuthProvider";
import {
  isTokenValid,
  performLogin,
  refreshToken,
} from "pages/authentication/services/AuthenticationServices";
import { WebServiceStatus } from "utils/services/AppUrls";
import {
  getOrgUser,
  getVerifiedPhoneNumber,
} from "pages/profile/services/ProfileServices";
import { UserInfo } from "pages/profile/utils/ProfileInterfaces";
import { CustomIndicator } from "pages/components/CustomIndicator";
import moment from "moment";
import RequiredFieldMarker from "pages/components/RequiredFieldMarker";

function SignIn(): JSX.Element {
  const location = useLocation();
  // // Get user from context
  const { userData } = useAuth();
  const storedUserData: UserInfo = userData;
  /// Decides whether there is any error or not
  const [hasError, setHasError] = useState<boolean>(false);
  const [error, setError] = useState<string>(
    "Invalid login credentials. Please try again."
  );
  /// Decides whether the form is valid or not
  const [isFormValid, setIsFormValid] = useState<boolean>(false);
  /// decides whether to display the password or not
  const [showPassword, setShowPassword] = useState<boolean>(false);
  /// Used to handle the email field
  const emailInputRef = useRef<HTMLInputElement>(null);
  /// Used to handle the password field
  const passwordInputRef = useRef<HTMLInputElement>(null);
  /// AuthContext
  const {
    login,
    setAccountSetupStatus,
    isSetupSuccess,
    authToken,
    storeAuthToken,
    storeUserData,
    logout,
    email,
    shouldRememberUser,
    shouldRemember,
    storeUserLoginTime,
  } = useAuth();
  /// Used for navigation
  const navigate = useNavigate();
  /// Decides whether to save the login state or not
  const [rememberMe, setRememberMe] = useState<boolean>(
    shouldRemember ?? false
  );
  /// State to hold the email entered by the user
  const [enteredEmail, setEnteredEmail] = useState<string>(
    shouldRemember ? email : ""
  );
  /// State to show/hide progress indicator
  const [showLoader, setShowLoader] = useState<boolean>(false);

  useEffect(() => {
    /// This validates the token
    const checkForValidTokenAndNavigateToDashboard = async () => {
      setShowLoader(true);
      const response = await isTokenValid(authToken);
      if (response.status === WebServiceStatus.success) {
        setShowLoader(false);
        navigate(RoutePath.vueDashboard, {
          replace: true,
        });
      } else {
        const response = await refreshToken(authToken);
        if (response.status === WebServiceStatus.success) {
          setShowLoader(false);
          /// Token is stored locally
          const authToken = response.data.body as string;
          storeAuthToken(authToken);
          navigate(RoutePath.vueDashboard, {
            replace: true,
          });
        } else {
          setShowLoader(false);
          await logout;
        }
      }
    };

    if (storedUserData) {
      checkForValidTokenAndNavigateToDashboard();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.state]);

  /// Timer to show the success alert
  useEffect(() => {
    const timer = setTimeout(() => {
      setAccountSetupStatus(false);
    }, 3000);
    return () => {
      clearTimeout(timer);
    };
  }, [setAccountSetupStatus]);

  ////// Methods /////////

  /// Helper method to update the 'rememberMe' state
  const handleSetRememberMe = () => {
    const updatedValue = !rememberMe;
    setRememberMe(updatedValue);
    shouldRememberUser(updatedValue);
  };

  /// Method toggle show password
  const handleClickShowPassword = () => setShowPassword((show) => !show);

  /// validates the entered email ID
  const updateEmailValidity = (event: React.ChangeEvent<HTMLInputElement>) => {
    const emailId = event.target.value.trim() ?? "";
    setEnteredEmail(emailId);
    if (emailId.isValidEmail()) {
      setHasError(false);
      setError("");
    }
    updateFormValidity();
  };

  /// validates the entered password
  const updatePasswordValidity = () => {
    const password = passwordInputRef.current.value?.trim() ?? "";
    if (password.isNotEmpty()) {
      setHasError(false);
      setError("");
    }
    updateFormValidity();
  };

  // Validates the login form and enables the sign in button
  const updateFormValidity = () => {
    const emailId = emailInputRef.current.value?.trim() ?? "";
    const password = passwordInputRef.current.value?.trim() ?? "";
    const isFormValid = emailId.isValidEmail() && password.length >= 8;
    setIsFormValid(isFormValid);
  };

  /// Handles the sign in button click and validates the inputs
  const handleSignInButtonClick = (event: React.FormEvent<HTMLDivElement>) => {
    event.preventDefault();
    const emailId = emailInputRef.current.value?.trim() ?? "";
    const password = passwordInputRef.current.value?.trim() ?? "";

    if (!emailId.isValidEmail()) {
      setHasError(true);
      setError("Invalid Email");
    } else if (password.length < 8) {
      setHasError(true);
      setError("Please enter the password.");
    } else {
      setHasError(false);
      proceedLogin(emailId, password);
    }
  };

  /// This validates the token
  const validateToken = async () => {
    setShowLoader(true);
    const response = await isTokenValid(authToken);
    if (response.status === WebServiceStatus.success) {
      setShowLoader(false);
      storeOrgUser();
    } else {
      const response = await refreshToken(authToken);
      if (response.status === WebServiceStatus.success) {
        setShowLoader(false);
        /// Token is stored locally
        const authToken = response.data.body as string;
        storeAuthToken(authToken);
        storeOrgUser();
      } else {
        setShowLoader(false);
        await logout;
      }
    }
  };

  /// Fetches the user details
  const storeOrgUser = async () => {
    setShowLoader(true);
    const response = await getOrgUser();
    if (response.status === WebServiceStatus.success) {
      setShowLoader(false);
      const responseData = response.data as UserInfo;
      const phoneNumber = responseData.phoneNumber;
      if (phoneNumber.isEmpty()) {
        /// For brand new user only
        const response = await getVerifiedPhoneNumber();
        if (response.status === WebServiceStatus.success) {
          const verifiedNumber = response.data.phoneNumber as string;
          storeUserData({ ...responseData, phoneNumber: verifiedNumber });
          navigate(RoutePath.vueDashboard, { replace: true });
        }
      } else {
        /// Storing the user data in context and locally
        storeUserData(responseData);
        navigate(RoutePath.vueDashboard, { replace: true });
      }
    } else {
      setShowLoader(false);
      await logout;
    }
  };

  /// Procees the email and password to complete the login
  const proceedLogin = async (
    enteredEmail: string,
    enteredPassword: string
  ) => {
    setShowLoader(true);
    const response = await performLogin({
      email: enteredEmail,
      password: enteredPassword,
    });
    if (response.status === WebServiceStatus.success) {
      setShowLoader(false);
      storeUserLoginTime(moment().toString());
      const storedEmail = email as string | null;
      if (!storedEmail) {
        /// If there is no user stored locally
        login(enteredEmail);
        navigate(RoutePath.authenticateUser, {
          replace: true,
        });
      } else if (storedEmail === enteredEmail) {
        /// If there is a user stored locally and if the same user is trying to login
        if (!authToken) {
          navigate(RoutePath.authenticateUser, {
            replace: true,
          });
        } else {
          validateToken();
        }
      } else {
        /// If a different user is trying to login
        storeAuthToken(null);
        login(enteredEmail);
        navigate(RoutePath.authenticateUser, {
          replace: true,
        });
      }
    } else {
      setShowLoader(false);
      setHasError(true);
      const error =
        response.error ?? "Invalid login credentials. Please try again.";
      setError(error);
    }
  };

  return (
    <>
      {showLoader && <CustomIndicator />}
      <CoverLayout image={bgImage}>
        <Card>
          <MDBox
            variant="gradient"
            bgColor="info"
            borderRadius="lg"
            coloredShadow="success"
            mx={2}
            mt={-3}
            p={2}
            mb={1}
            textAlign="center"
          >
            <MDTypography
              variant="h5"
              fontWeight="medium"
              color="white"
              mt={1}
              pb={1}
            >
              Sign In
            </MDTypography>

            <MDTypography
              display="block"
              variant="button"
              color="white"
              my={1}
              fontWeight="light"
            >
              Enter your email and password to Sign In
            </MDTypography>
          </MDBox>
          {isSetupSuccess && (
            <MDBox px={2}>
              <MessageAlert
                message="You have successfully set up your account."
                type="success"
                icon={<CheckCircleOutlineRounded />}
              />
            </MDBox>
          )}
          {hasError && (
            <MDBox px={2}>
              <MessageAlert
                message={error}
                type="error"
                icon={<InfoOutlined />}
              />
            </MDBox>
          )}
          <MDBox pt={hasError || isSetupSuccess ? 0 : 4} pb={6} px={3}>
            <MDBox
              component="form"
              role="form"
              onSubmit={handleSignInButtonClick}
            >
              <MDBox mb={2}>
                <MDTypography
                  variant="button"
                  fontWeight="bold"
                  sx={{ fontSize: 14 }}
                >
                  Email
                  <RequiredFieldMarker />
                </MDTypography>

                <MDInput
                  type="email"
                  value={enteredEmail}
                  fullWidth
                  inputRef={emailInputRef}
                  placeholder="Email Address"
                  onChange={updateEmailValidity}
                  InputLabelProps={{ shrink: true }}
                />
              </MDBox>
              <MDBox mb={2}>
                <MDTypography
                  variant="button"
                  fontWeight="bold"
                  sx={{ fontSize: 14 }}
                >
                  Password
                  <RequiredFieldMarker />
                </MDTypography>
                <MDInput
                  type={showPassword ? "text" : "password"}
                  fullWidth
                  placeholder="Password"
                  inputRef={passwordInputRef}
                  InputLabelProps={{ shrink: true }}
                  onChange={updatePasswordValidity}
                  sx={{
                    "input[type=password]": {
                      "::-ms-reveal, ::-ms-clear": {
                        display: "none",
                      },
                    },
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          edge="end"
                          onClick={handleClickShowPassword}
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </MDBox>
              <MDBox
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                ml={-1}
              >
                <MDBox display="flex" alignItems="center" ml={-1}>
                  <Switch checked={rememberMe} onChange={handleSetRememberMe} />
                  <MDTypography
                    variant="button"
                    fontWeight="regular"
                    color="text"
                    onClick={handleSetRememberMe}
                    sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
                  >
                    &nbsp;&nbsp;Remember me
                  </MDTypography>
                </MDBox>
                <MDBox alignItems="center">
                  <MDTypography variant="button" color="text">
                    <MDTypography
                      component={Link}
                      to={RoutePath.resetPassword}
                      variant="button"
                      color="primary"
                      fontWeight="regular"
                      textGradient
                      underline="always"
                      sx={{
                        textDecoration: "underline !important",
                        textFillColor: "unset",
                        "&:link, &:visited": {
                          textDecoration: "underline !important",
                        },
                        "&:hover": {
                          textDecoration: "none !important",
                        },
                      }}
                    >
                      Forgot Password
                    </MDTypography>
                  </MDTypography>
                </MDBox>
              </MDBox>
              <MDBox mt={4} mb={1}>
                <MDButton
                  type="submit"
                  variant="gradient"
                  color="info"
                  fullWidth
                  disabled={!isFormValid}
                >
                  SIGN IN
                </MDButton>
              </MDBox>
            </MDBox>
          </MDBox>
        </Card>
      </CoverLayout>
    </>
  );
}

export default SignIn;
