import { Divider, Grid } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import PortalItem from "pages/portal/components/PortalItem";
import { useAuth } from "context/AuthProvider";
import { Company } from "pages/profile/utils/ProfileInterfaces";
import { alphanumericSort, parseJWTToken } from "utils/helpers/extensions";
import { UserRoles } from "pages/my-account/manage-users/interfaces/interfaces";
import { UserRolesPortal } from "./types/types";

const Portal = () => {
  /// Fetching user details from local storage
  const { getAvailablePortalsForUser, authToken } = useAuth();
  const companiesList: Company[] = getAvailablePortalsForUser();
  const myRoles: any = parseJWTToken(authToken).cmproles ?? {};

  const getRoleForCompany = (companyId: string):UserRolesPortal => {
    const role = myRoles[companyId] ?? "";
    switch (true) {
      case role === UserRoles.superAdmin: {
        return UserRolesPortal.superAdmin;
      }
      case role === UserRoles.manager: {
        return UserRolesPortal.manager;
      }
      case role === UserRoles.admin: {
        return UserRolesPortal.admin;
      }
      case role === UserRoles.user: {
        return UserRolesPortal.user;
      }
      case role === UserRoles.noAccess: {
        return UserRolesPortal.noAccess;
      }
      case role === UserRoles.immutableReadOnly: {
        return UserRolesPortal.immutableReadOnly;
      }
      default: {
        return UserRolesPortal.noAccess;
      }
    }
  }
  return (
    <>
      <Grid item xs={12} md={6} lg={4} sx={{ ml: "auto" }}></Grid>
      <MDBox px={3} py={2}>
        <Grid container spacing={2} mt={5} ml={0.5}>
          <Grid item xs={5}>
            <MDTypography sx={{ fontSize: 14 }} fontWeight="bold">
              PORTAL NAME
            </MDTypography>
          </Grid>
          <Grid item xs={"auto"}>
            <MDTypography sx={{ fontSize: 14 }} fontWeight="bold">
              MY ROLE
            </MDTypography>
          </Grid>
        </Grid>
        <Grid item xs={true}>
          <Divider
            sx={{
              backgroundColor: "#c7ccd0",
              height: "1x",
              opacity: "0.5",
              backgroundImage: "none !important",
              margin: "0.5rem 0 0.7rem",
            }}
          />
        </Grid>
        {/* Portal Items */}
        {companiesList
          .map((company) => {
            return (
              <PortalItem
                key={company.id}
                name={company.name}
                role={getRoleForCompany(company.id)}
                profileImageId={company.logoFileId}
              />
            );
            /// Sorts according to the role
          })
          .sort((a,b) => alphanumericSort(a.props.name, b.props.name))
          .sort((a, b) => (b.props.role === UserRoles.noAccess ? -1 : 0))}
      </MDBox>
    </>
  );
};

export default Portal;
