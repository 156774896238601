import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

interface Props {
    title: string;
    version: string;
}

const VersionListItem = (props:Props) => {
  return (
    <>
      <MDBox>
        <MDTypography
          variant="body"
          sx={{
            fontWeight: 500,
            fontSize: "14px",
          }}
        >
          {props.title}: 
        </MDTypography>
        <MDTypography
          variant="body"
          sx={{
            fontWeight: 400,
            fontSize: "14px",
            ml: 1,
          }}
        >
          {props.version}
        </MDTypography>
      </MDBox>
    </>
  );
};

export default VersionListItem;
