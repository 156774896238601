import { WebServiceResponse } from "@ivueit/vue-engine";
import { InvoicesAPIServices } from "utils/services/AppUrls";
import { performGetRequest } from "utils/services/NetworkHandler";

export const getInvoicesForMyCompany = async (
  parameters: string
): Promise<WebServiceResponse> => {
  const url = InvoicesAPIServices.getInvoicesInfo + "?" + parameters;
  return await performGetRequest(url);
};

export const getInvoiceByID = async (
  id: string
): Promise<WebServiceResponse> => {
  const url = `${InvoicesAPIServices.getInvoicesInfo}/${id}`;
  return await performGetRequest(url);
};
