import { DialogContent, DialogActions } from "@mui/material";
import { GridRowSelectionModel } from "@mui/x-data-grid-premium";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import React, { useState } from "react";
import { cancelVue } from "../../services/VueServices";
import { WebServiceStatus } from "@ivueit/vue-engine";
import { CustomIndicator } from "pages/components/CustomIndicator";
import RequiredFieldMarker from "pages/components/RequiredFieldMarker";
import CustomSnackbar, {
  CustomSnackbarContent,
} from "pages/components/CustomSnackbar";
import { GENERIC_ERROR_MESSAGE } from "./../../../../../../constants";

export const CancelVueDialogContent: React.FC<{
  selectedVueList: GridRowSelectionModel;
  closeDialog: (hasCancelled: boolean) => void;
}> = (props) => {
  const [showLoader] = useState<boolean>(false);
  const [reason, setReason] = useState<string>("");
  const [processing, setProcessing] = useState(false);
  const [error, setError] = useState<string>("");
  const [snackbarContent, setSnackbarContent] =
    useState<CustomSnackbarContent | null>(null);

  /// Handles the onChange of "Reason" field
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    /// Max character length should be 500
    if (value.length <= 500) {
      setReason(value);
    } else {
      setReason(value.slice(0, 500));
    }
  };

  /// Handles the onClick of "Yes, Cancel" button
  const handleCancelButtonClick = async () => {
    const isValid = reason.isNotEmpty();
    if (isValid) {
      /// On success, returns true, orElse false
      setError("");
      const ids = props.selectedVueList.map((item) => item);
      const params = {
        ids: ids,
        reason: reason,
      };
      const response = await cancelVue(params);
      if (response.status === WebServiceStatus.success) {
        props.closeDialog(true);
      } else {
        setSnackbarContent({
          title: "Attention!",
          message: response.error ?? GENERIC_ERROR_MESSAGE,
          isError: true,
        });
      }
    } else {
      setError("Please enter a reason");
    }
  };

  /// Handles the onClick of "No" button
  const handleNoButtonClick = () => {
    props.closeDialog(false);
  };

  return (
    <MDBox px={1} py={1}>
      {/* Title */}
      {showLoader && <CustomIndicator />}
      <DialogContent>
        <MDTypography
          sx={{
            color: "#344767",
            fontSize: "14px",
            fontWeight: "500",
          }}
        >
          Are you sure you want to cancel {props.selectedVueList.length} Vue(s)?
        </MDTypography>
        <MDTypography
          sx={{
            color: "#344767",
            fontSize: "14px",
            fontWeight: "500",
            pt: 2,
          }}
        >
          <p>
            <strong>NOTE:</strong> All Open Vues will be cancelled immediately.
          </p>
          <p>
            Any In Progress/In Review Vues will be cancelled out at the end of
            the Vue window if not completed.
          </p>
        </MDTypography>
        {/* TextArea  */}
        <MDBox pt={4} pb={1}>
          <MDBox display={"flex"}>
            <MDTypography
              sx={{
                color: "#344767",
                fontSize: "14px",
              }}
            >
              <strong>Reason</strong>
            </MDTypography>
            <RequiredFieldMarker />
          </MDBox>
          <MDInput
            value={reason}
            placeholder="Enter text here"
            fullWidth
            multiline
            rows={5}
            onChange={handleInputChange}
          />
          {error && (
            <MDTypography
              variant="caption"
              color="error"
              sx={{ fontWeight: "normal" }}
            >
              {error}
            </MDTypography>
          )}
        </MDBox>
      </DialogContent>
      {/* Action buttons */}
      <DialogActions>
        <MDButton
          variant="outlined"
          color="info"
          size="medium"
          sx={{
            borderColor: "secondary.light",
            color: "dark.main",
            fontSize: 14,
            "&:hover": {
              backgroundColor: "white.main",
              borderColor: "secondary.light",
              color: "dark.main",
            },
            "&:focus": { color: "dark.main" },
          }}
          disabled={processing}
          onClick={handleNoButtonClick}
        >
          NO
        </MDButton>
        <MDButton
          variant="gradient"
          color="info"
          size="medium"
          onClick={() => {
            setProcessing(true);
            handleCancelButtonClick();
          }}
          disabled={reason.isEmpty() || processing}
        >
          YES, CANCEL
        </MDButton>
      </DialogActions>
      <CustomSnackbar
        snackbarContent={snackbarContent}
        onClose={() => {
          setSnackbarContent(null);
        }}
      />
    </MDBox>
  );
};
