import moment from "moment";
import { ElapsedType, NotificationType } from "./constants";

export const getNotificationTitle = (type: NotificationType) => {
  switch (type) {
    case NotificationType.completed:
      return "Vue completed";
    case NotificationType.escalated:
      return "Vue escalated";
    case NotificationType.exportReady:
      return "Your export is ready";
    default:
      break;
  }
};
export const calculateElapsedTime = (nanosecond: string) => {
  const dateTimeFormat = "MM/DD/YYYY HH:mm:ss";
  const formattedDateTime =
    nanosecond.formatUTCNanoSecondsToString(dateTimeFormat);
  const elapsedTime = moment(formattedDateTime).fromNow();
  return elapsedTime.toUpperCase() === "A DAY AGO" ? "1 day ago" : elapsedTime;
};

export const differenceInDays = (type: ElapsedType, nanosecond: string) => {
  const dateTimeFormat = "MM/DD/YYYY HH:mm:ss";
  const formattedDate = nanosecond.formatUTCNanoSecondsToString(dateTimeFormat);
  switch (type) {
    case ElapsedType.newest:
      return moment().diff(moment(formattedDate), "days") <= 7;
    case ElapsedType.oldest:
      return moment().diff(moment(formattedDate), "days") > 7;
    default:
      return false;
  }
};
