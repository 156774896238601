/** 
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that contains other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// Material Dashboard 2 PRO React TS components
import Icon from "@mdi/react";
import {
  mdiClipboardList,
  mdiCog,
  mdiHelpCircleOutline,
  mdiNoteTextOutline,
  mdiViewListOutline,
  mdiFileDocument,
  mdiMultimedia,
} from "@mdi/js";

import {
  AccountCircle,
  AddAPhoto,
  BarChart,
  Dashboard,
  PhotoCamera,
} from "@mui/icons-material";
import { RoutePath } from "./constants";
import Vues from "pages/dashboard/home/vues/Vues";
import SiteLists from "pages/dashboard/home/site-lists/SiteLists";
import AnalyticsHome from "pages/dashboard/analytics/analytics-home/AnalyticsHome";
import CustomerSupport from "pages/support/Support";
import ManageUsers from "pages/my-account/manage-users/ManageUsers";
import Invoices from "pages/my-account/invoices/Invoices";
import Profile from "pages/profile/Profile";
import Surveys from "pages/dashboard/home/surveys/Surveys";
import DashboardHome from "pages/dashboard/home/dashboard-home/DashboardHome";
import CreateVues from "pages/dashboard/home/create-vues/CreateVues";
import InternalVuerNetworkLanding from "pages/my-account/manage-internal-vuer-network/InternalVuerNetworkLanding";
import { RestrictedRoute } from "utils/ProtectedRoute/RestrictedRoute";
import Settings from "pages/settings/Settings";
import { UserRoles } from "pages/my-account/manage-users/interfaces/interfaces";
import MediaHubHome from "pages/dashboard/home/media-hub/MediaHubHome";
import { Batches } from "pages/dashboard/batches/Batches";

export const getRoutesList = (fullName: string, profilePic: JSX.Element) => {
  const routes = [
    {
      type: "collapse",
      name: fullName,
      key: "user",
      noCollapse: true,
      icon: profilePic,
      route: RoutePath.profile,
      requiredRole: UserRoles.noAccess,
      component: <Profile />,
    },
    { type: "divider", key: "divider-0" },
    {
      type: "collapse",
      name: "Create Vues",
      key: "create-vues",
      icon: <AddAPhoto sx={{ fontSize: "1.2rem !important" }} />,
      route: RoutePath.createVues,
      requiredRole: UserRoles.manager,
      component: <CreateVues />,
      noCollapse: true,
    },
    { type: "divider", key: "divider-1", requiredRole: UserRoles.manager },
    {
      type: "collapse",
      name: "Vue Dashboard",
      key: "dashboard",
      icon: <Dashboard sx={{ fontSize: "1.2rem !important" }} />,
      route: RoutePath.vueDashboard,
      requiredRole: UserRoles.immutableReadOnly,
      component: <DashboardHome />,
      collapse: [
        {
          name: "Vues",
          key: "vues",
          icon: <PhotoCamera sx={{ fontSize: "1.2rem !important" }} />,
          route: RoutePath.vues,
          requiredRole: UserRoles.immutableReadOnly,
          component: <Vues />,
        },
        {
          name: "Batches",
          key: "batches",
          icon: <Icon path={mdiViewListOutline} size={0.8} />,
          route: RoutePath.batches,
          requiredRole: UserRoles.immutableReadOnly,
          component: <Batches />,
        },
        {
          name: "Surveys",
          key: "surveys",
          icon: <Icon path={mdiNoteTextOutline} size={0.8} />,
          route: RoutePath.surveys,
          requiredRole: UserRoles.immutableReadOnly,
          component: <Surveys />,
        },
        {
          name: "Site Lists",
          key: "site-lists",
          icon: <Icon path={mdiClipboardList} size={0.8} />,
          route: RoutePath.siteLists,
          requiredRole: UserRoles.manager,
          component: <SiteLists />,
        },
        {
          name: "Media Hub",
          key: "media-hub",
          icon: <Icon path={mdiMultimedia} size={0.8} />,
          route: RoutePath.mediahub,
          requiredRole: UserRoles.user,
          component: <MediaHubHome />,
        },
      ],
    },
    {
      type: "collapse",
      name: "Analytics",
      key: "analytics",
      icon: <BarChart sx={{ fontSize: "1.2rem !important" }} />,
      route: RoutePath.analytics,
      requiredRole: UserRoles.user,
      component: <AnalyticsHome />,
      noCollapse: true,
    },
    {
      type: "collapse",
      name: "My Account",
      key: "my-account",
      icon: <AccountCircle sx={{ fontSize: "1.2rem !important" }} />,
      requiredRole: UserRoles.admin,
      collapse: [
        {
          name: "Manage Users",
          key: "manage-users",
          icon: <Icon path={mdiClipboardList} size={0.8} />,
          route: RoutePath.manageUsers,
          requiredRole: UserRoles.admin,
          component: (
            <RestrictedRoute requiredRole={UserRoles.admin}>
              <ManageUsers />
            </RestrictedRoute>
          ),
        },
        {
          name: "Manage Internal Vuer Network",
          key: "manage-internal-vuer-network",
          icon: <Icon path={mdiClipboardList} size={0.8} />,
          route: RoutePath.manageInternalVuerNetwork,
          requiredRole: UserRoles.admin,
          component: (
            <RestrictedRoute requiredRole={UserRoles.admin}>
              <InternalVuerNetworkLanding />
            </RestrictedRoute>
          ),
        },
        // TODO: COMMENTED TEMPORARILY TO HIDE INVOICES
        // {
        //   name: "Invoices",
        //   key: "invoices",
        //   icon: <Icon path={mdiFileDocument} size={0.8} />,
        //   route: RoutePath.invoices,
        //   requiredRole: UserRoles.admin,
        //   component: (
        //     <RestrictedRoute requiredRole={UserRoles.admin}>
        //       <Invoices />
        //     </RestrictedRoute>
        //   ),
        // },
      ],
    },
    {
      type: "collapse",
      name: "Settings",
      key: "settings",
      icon: <Icon path={mdiCog} size={1} />,
      route: RoutePath.settings,
      requiredRole: UserRoles.manager,
      component: (
        <RestrictedRoute requiredRole={UserRoles.manager}>
          <Settings />
        </RestrictedRoute>
      ),
      noCollapse: true,
    },
    {
      type: "collapse",
      name: "Support",
      key: "support",
      icon: <Icon path={mdiHelpCircleOutline} size={1} />,
      route: RoutePath.customersupport,
      requiredRole: UserRoles.noAccess,
      component: <CustomerSupport />,
      noCollapse: true,
    },
  ];
  return routes;
};
