import { mdiPlay, mdiPause, mdiVideo } from "@mdi/js";
import { IconButton } from "@mui/material";
import Icon from "@mdi/react";
import MDBox from "components/MDBox";
import { useRef, useState } from "react";
import {
  playButtonStyle,
  pauseButtonStyle,
  controlButtonStyle,
} from "pages/dashboard/home/vues/styles/VueDetailStyles";

export const VideoPlayer = ({
  sourceURL,
  shouldPlay,
  mimeType,
}: {
  sourceURL: string;
  shouldPlay: boolean;
  mimeType?: string;
}) => {
  const [isPlaying, setIsPlaying] = useState<boolean>(false);
  const videoRef = useRef(null);

  const togglePlay = () => {
    if (shouldPlay) {
      const video = videoRef.current;
      if (video.paused) {
        video.play();
        setIsPlaying(true);
      } else {
        video.pause();
        setIsPlaying(false);
      }
    }
  };

  return (
    <>
      <MDBox
        onClick={() => {
          togglePlay();
        }}
        sx={{
          cursor: shouldPlay ? "pointer" : "default",
          position: "relative",
          height: "100%",
          background: "#000",
          video: {
            width: "100%",
            height: "300px",
          },
          ...(!isPlaying ? playButtonStyle : pauseButtonStyle),
        }}
      >
        {shouldPlay ? (
          <IconButton
            sx={{
              ...controlButtonStyle,
              cursor: shouldPlay ? "pointer" : "default",
            }}
          >
            <Icon path={!isPlaying ? mdiPlay : mdiPause} size={3} />
          </IconButton>
        ) : (
          <Icon color="#ffffff" path={mdiVideo} size={3} />
        )}
        <video
          ref={videoRef}
          controls={isPlaying}
          controlsList="nodownload"
          onPause={() => {
            setIsPlaying(false);
          }}
          onEnded={() => {
            setIsPlaying(false);
          }}
        >
          {/* Specifying source seperately to work with Safari*/}
          <source src={sourceURL} type={mimeType ?? "video/mp4"} />
        </video>
      </MDBox>
    </>
  );
};
