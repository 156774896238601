import { searchBarStyle } from "../../vues/styles/VueStyles";
import { TextField, InputAdornment, Grid } from "@mui/material";
import { Search } from "@mui/icons-material";
import {
  CustomDatePicker,
  CustomDialogBox,
  CustomDropdown,
  CustomDropdownOption,
  WebServiceStatus,
  downloadFileToDevice,
} from "@ivueit/vue-engine";
import { useCallback, useEffect, useRef, useState } from "react";
import MDBox from "components/MDBox";
import { Moment } from "moment";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import { AlbumDialogContent } from "./AlbumDialogContent";
import AlbumItem from "./AlbumItem";
import { useNavigate } from "react-router";
import {
  DEFAULT_PAGE_SIZE,
  GENERIC_ERROR_MESSAGE,
  RoutePath,
  TEXTFIELD_CHANGE_DELAY,
} from "../../../../../constants";
import { AlbumInfo, ClientMediaAPIMetaData } from "../utils/interfaces";
import { defaultMetaData } from "../utils/constants";
import { filterDateToNanoSecondsString } from "../../vues/vue-grid/helpers/helper_methods";
import {
  createNewAlbum,
  deleteAlbumWithAlbumId,
  exportAlbumsAsPDF,
  getClientMediaAlbumsInfo,
  renameAlbumNameWithAlbumId,
} from "../services/MediaHubServices";
import { CustomIndicator } from "pages/components/CustomIndicator";
import CustomSnackbar, {
  CustomSnackbarContent,
} from "pages/components/CustomSnackbar";
import { ConfirmationDialog } from "../../site-lists/components/ConfirmationDialog";

const availableSortTypes = ["Creation Date", "Alphabetically"];

const MediaHubAlbums = ({}: {}) => {
  const navigate = useNavigate();
  const [searchText, setSearchText] = useState<string>("");
  const [showLoader, setShowLoader] = useState<boolean>(false);
  /// For scrolling
  const containerRef = useRef<HTMLDivElement>(null);
  const [albumList, setAlbumList] = useState<AlbumInfo[]>([]);
  const [sortType, setSortType] = useState<string>("Creation Date");
  const [searchTextChanged, setSearchTextChanged] = useState<boolean>(false);
  const [albumToUpdate, setAlbumToUpdate] = useState<AlbumInfo>(null);
  const [showAlbumDialog, setShowAlbumDialog] = useState<boolean>(false);
  const [meta, setMeta] = useState<ClientMediaAPIMetaData>(defaultMetaData);
  const [startDate, setStartDate] = useState<Moment | null>(null);
  const [endDate, setEndDate] = useState<Moment | null>(null);
  const [snackbarContent, setSnackbarContent] =
    useState<CustomSnackbarContent | null>(null);
  const [showDeleteConfirmationDialog, setShowDeleteConfirmationDialog] =
    useState<boolean>(false);
  const [dateError, setDateError] = useState({
    startDate: "",
    endDate: "",
  });

  const handleDropDownClick = (newValue: string) => {
    setSortType(newValue);
  };

  /// Handles the onChange of startDate and endDate
  const handleStartDateChange = (newDate: Moment | null) => {
    const error = newDate
      ? (endDate && newDate && newDate > endDate) || !newDate.isValid()
        ? "Invalid start date."
        : ""
      : "";
    setDateError((prev) => ({
      startDate: error,
      endDate: "",
    }));
    const date = newDate !== null && newDate.isValid() ? newDate : null;
    setStartDate(date);
  };

  const handleEndDateChange = (newDate: Moment | null) => {
    const error = newDate
      ? (startDate && startDate > newDate) || !newDate.isValid()
        ? "Invalid end date."
        : ""
      : "";
    setDateError((prev) => ({
      startDate: "",
      endDate: error,
    }));
    const date = newDate !== null && newDate.isValid() ? newDate : null;
    setEndDate(date);
  };

  const getSearchTextfield = () => {
    return (
      <TextField
        fullWidth
        value={searchText}
        placeholder="Search"
        sx={searchBarStyle}
        InputLabelProps={{ shrink: true }}
        onChange={(event) => {
          setSearchTextChanged(true);
          setSearchText(event.target.value);
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <Search fontSize="medium" sx={{ color: "#344767" }} />
            </InputAdornment>
          ),
        }}
      />
    );
  };

  const getDatePickers = () => {
    const helperTextStyle = {
      "p.MuiFormHelperText-root": {
        position: "absolute",
        bottom: "-16px",
        left: "5px",
      },
    };
    return (
      <MDBox ml={3} display="flex">
        <MDBox ml={1} position="relative" sx={helperTextStyle}>
          <CustomDatePicker
            title="Start Date"
            defaultDate={null}
            minimumDate={startDate}
            onChange={handleStartDateChange}
            errorMessage={dateError.startDate}
          />
        </MDBox>
        <MDBox ml={1} position="relative" sx={helperTextStyle}>
          <CustomDatePicker
            title="End Date"
            defaultDate={null}
            minimumDate={endDate}
            onChange={handleEndDateChange}
            errorMessage={dateError.endDate}
          />
        </MDBox>
      </MDBox>
    );
  };

  const getLeftTopbarItems = () => {
    return (
      <MDBox display="flex" alignItems="end" flexGrow="1">
        {getSearchTextfield()}
        <CustomDropdown
          title={"Sort by"}
          prefixTitle={true}
          minWidth="200px"
          selectedItem={{
            value: sortType,
            displayTitle: sortType,
          }}
          availableValues={availableSortTypes.map((type) => {
            return {
              value: type,
              displayTitle: type,
            };
          })}
          onChange={(newValue: CustomDropdownOption) => {
            handleDropDownClick(newValue.value);
          }}
        />
        {getDatePickers()}
      </MDBox>
    );
  };

  const getRightTopBarItems = () => {
    return (
      <MDBox display="flex" alignItems="end" flexGrow="1" pl="10px">
        <MDBox mr={1} display="flex" flexDirection="column" alignItems="end">
          <MDTypography variant="button" color="textLight" fontWeight="regular">
            Albums
          </MDTypography>
          <MDTypography variant="h5">{meta.totalElements}</MDTypography>
        </MDBox>
        <MDButton
          variant="contained"
          color="info"
          onClick={() => {
            setAlbumToUpdate(null);
            setShowAlbumDialog(true);
          }}
          disabled={false}
        >
          CREATE ALBUM
        </MDButton>
      </MDBox>
    );
  };

  const getNoMediaLabel = () => {
    return (
      <MDBox display="flex" justifyContent="center" mt="15vh">
        <MDTypography>
          {showLoader
            ? "Loading..."
            : "There are currently no albums to display."}
        </MDTypography>
      </MDBox>
    );
  };

  const getDeleteAlbumConfirmationDialog = () => {
    return (
      <CustomDialogBox
        title={"Delete Album"}
        width="450px"
        openDialog={showDeleteConfirmationDialog}
      >
        <ConfirmationDialog
          message={
            "Are you sure you want to delete this album? Deleting the album shall delete all photos in it. This action cannot be undone."
          }
          buttonName={"Yes, Delete"}
          onClose={() => {
            setShowDeleteConfirmationDialog(false);
          }}
          onClick={deleteAlbum}
        />
      </CustomDialogBox>
    );
  };

  const deleteAlbum = async () => {
    setShowLoader(true);
    const response = await deleteAlbumWithAlbumId(albumToUpdate.albumId);
    if (response.status === WebServiceStatus.success) {
      fetchAlbumMediaInfoFromServer(false);
      setSnackbarContent({
        title: "Success",
        message: `Album "${albumToUpdate.name}" deleted successfully`,
        isError: false,
      });
    } else {
      setSnackbarContent({
        title: "Attention!",
        message: response.error ?? GENERIC_ERROR_MESSAGE,
        isError: true,
      });
    }
    setAlbumToUpdate(null);
    setShowLoader(false);
    setShowDeleteConfirmationDialog(false);
  };

  const exportAlbumAsPDF = async (album: AlbumInfo) => {
    setShowLoader(true);
    const response = await exportAlbumsAsPDF(album.albumId);
    if (response.status === WebServiceStatus.success) {
      const presignedUrl = response.data.url as string;
      const formattedAlbumName = encodeURIComponent(album.name);
      try {
        await downloadFileToDevice(
          presignedUrl,
          `Album_${formattedAlbumName}_Media`,
          "text/csv"
        );
      } catch (error) {
        setSnackbarContent({
          title: "Attention!",
          message: "Failed to download the file",
          isError: true,
        });
      }
      setSnackbarContent({
        title: "Success",
        message: `Album "${album.name}" exported successfully`,
        isError: false,
      });
    } else {
      setSnackbarContent({
        title: "Attention!",
        message: response.error ?? GENERIC_ERROR_MESSAGE,
        isError: true,
      });
    }
    setAlbumToUpdate(null);
    setShowLoader(false);
  };

  const getAlbums = () => {
    return (
      <>
        <Grid container rowSpacing={5} columnSpacing={2}>
          {albumList.map((album, index) => (
            <Grid item xs={12} md={4} key={index}>
              <AlbumItem
                content={album}
                onRenameButtonClick={() => {
                  setShowAlbumDialog(true);
                  setAlbumToUpdate(album);
                }}
                onCreatePdfButtonClick={() => {
                  exportAlbumAsPDF(album);
                }}
                onDeleteButtonClick={() => {
                  setShowDeleteConfirmationDialog(true);
                  setAlbumToUpdate(album);
                }}
                onClick={() => {
                  // Encoded the alubm name to avoid special character related issues
                  const encodedAlbumName = encodeURIComponent(album.name);
                  navigate(`${RoutePath.mediahub}/${encodedAlbumName}`, {
                    state: {
                      albumId: album.albumId,
                    },
                  });
                }}
              />
            </Grid>
          ))}
        </Grid>
      </>
    );
  };

  const getCreateAlbumDialog = () => {
    return (
      <CustomDialogBox
        title={albumToUpdate ? "Rename Album" : "Create New Album"}
        width="440px"
        openDialog={showAlbumDialog}
      >
        <AlbumDialogContent
          existingName={!albumToUpdate ? "" : albumToUpdate.name}
          handleCancelClick={() => {
            setShowAlbumDialog(false);
          }}
          handleSaveClick={async (name: string) => {
            const newName = name.trim();
            if (newName.isEmpty()) {
              setSnackbarContent({
                title: "Attention!",
                message: "Album name should not be empty.",
                isError: true,
              });
              return;
            }

            if (!albumToUpdate) {
              await createAlbum(newName);
            } else {
              await renameAlbumName(albumToUpdate.albumId, name);
            }

            setAlbumToUpdate(null);
            setShowAlbumDialog(false);
          }}
        />
      </CustomDialogBox>
    );
  };

  // Creates new album
  const createAlbum = async (name: string) => {
    setShowLoader(true);
    const response = await createNewAlbum(name);
    if (response.status === WebServiceStatus.success) {
      fetchAlbumMediaInfoFromServer(false);
      setSnackbarContent({
        title: "Success",
        message: `Album "${name}" created successfully`,
        isError: false,
      });
    } else {
      setSnackbarContent({
        title: "Attention!",
        message: response.error ?? GENERIC_ERROR_MESSAGE,
        isError: true,
      });
    }
    setShowLoader(false);
  };

  // Renames an album
  const renameAlbumName = async (albumId: string, name: string) => {
    setShowLoader(true);
    const response = await renameAlbumNameWithAlbumId(albumId, name);
    if (response.status === WebServiceStatus.success) {
      const newName = response.data.name as string;
      fetchAlbumMediaInfoFromServer(false);
      setSnackbarContent({
        title: "Success",
        message: `Album name has been changed to "${newName}" successfully`,
        isError: false,
      });
    } else {
      setSnackbarContent({
        title: "Attention!",
        message: response.error ?? GENERIC_ERROR_MESSAGE,
        isError: true,
      });
    }
    setShowLoader(false);
  };

  const getParameterString = (isScrolling: boolean) => {
    // Default page number will be 1 if not scrolling
    var newPageNumber = isScrolling ? meta.pageNumber + 1 : 1;
    var pageSize = DEFAULT_PAGE_SIZE;
    var parameterString = `pageSize=${pageSize}&pageNumber=${newPageNumber}`;
    /// Appending Site Name
    if (searchText.isNotEmpty()) {
      parameterString += `&albumQueryString=${searchText}`;
    }
    if (sortType.isNotEmpty()) {
      const displayName =
        sortType.toLowerCase() === "creation date" ? "CREATED AT" : "ALBUM";
      const isAscending = sortType.toLowerCase() === "alphabetically";
      parameterString += `&sortIsAscending=${isAscending}&sortColumnDisplayName=${displayName}`;
    }
    /// Appending created start & end dates
    if (startDate !== null && endDate !== null) {
      const formattedStartDate = filterDateToNanoSecondsString(startDate);
      const formattedEndDate = filterDateToNanoSecondsString(endDate, true);
      parameterString += `&createdAtRange.startTime=${formattedStartDate}&createdAtRange.endTime=${formattedEndDate}`;
    }
    return parameterString;
  };

  // Check for the valid start date and end date
  const isValidParameter = () => {
    const isValid =
      (startDate?.isValid() && endDate?.isValid()) ||
      (!startDate?.isValid() && !endDate?.isValid());
    return isValid && !dateError.startDate && !dateError.endDate;
  };

  const fetchAlbumMediaInfoFromServer = useCallback(
    async (isScrolling: boolean) => {
      if (isValidParameter()) {
        setShowLoader(true);
        const parameters = getParameterString(isScrolling);
        const response = await getClientMediaAlbumsInfo(parameters);
        var albumsList: AlbumInfo[] = [];
        if (response.status === WebServiceStatus.success) {
          const {
            albums,
            meta,
          }: { albums: AlbumInfo[]; meta: ClientMediaAPIMetaData } =
            response.data;
          albumsList = albums;
          if (meta) {
            setMeta({
              pageSize: Number(meta.pageSize),
              pageNumber: Number(meta.pageNumber),
              totalPages: Number(meta.totalPages),
              totalElements: Number(meta.totalElements),
            });
          }
        } else {
          setAlbumList([]);
          setSnackbarContent({
            title: "Attention!",
            message: `Unable to load albums: ${response.error}`,
            isError: true,
          });
        }
        if (isScrolling) {
          setAlbumList((prev) => prev.concat(albumsList));
        } else {
          setAlbumList(albumsList);
        }
        setShowLoader(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [getParameterString]
  );

  useEffect(() => {
    if (searchTextChanged) {
      // performs filter on given field and text
      const performAlbumSearch = (searchText: string) => {
        setSearchText(searchText);
        fetchAlbumMediaInfoFromServer(false);
      };
      /// The method delays the callback for 700 millseconds
      const delaySearchAction = setTimeout(() => {
        performAlbumSearch(searchText.trim());
      }, TEXTFIELD_CHANGE_DELAY);
      return () => clearTimeout(delaySearchAction);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText]);

  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      const handleOnRowsScrollEnd = async () => {
        const { scrollTop, scrollHeight, clientHeight } = container;
        const hasReachedEnd =
          Math.abs(scrollHeight - clientHeight - scrollTop) < 1;
        const shouldScroll =
          albumList.length < meta.totalElements &&
          meta.pageNumber < meta.totalPages;
        if (shouldScroll && hasReachedEnd) {
          await fetchAlbumMediaInfoFromServer(true);
        }
      };
      container.addEventListener("scroll", handleOnRowsScrollEnd);
      return () => {
        container.removeEventListener("scroll", handleOnRowsScrollEnd);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [albumList, meta]);

  useEffect(() => {
    fetchAlbumMediaInfoFromServer(false);
  }, [startDate, endDate, sortType]);

  return (
    <MDBox>
      {showLoader && <CustomIndicator />}
      {showAlbumDialog && getCreateAlbumDialog()}
      {showDeleteConfirmationDialog && getDeleteAlbumConfirmationDialog()}
      {/* Top bar items */}
      <MDBox display="flex" alignItems="start" mb={4}>
        <MDBox display="flex" flexGrow={1}>
          <MDBox>{getLeftTopbarItems()}</MDBox>
          <MDBox display="flex" ml="auto">
            {getRightTopBarItems()}
          </MDBox>
        </MDBox>
      </MDBox>
      <MDBox
        sx={{
          height: "calc(100vh - 256px)",
          overflow: "auto",
          paddingTop: "48px",
        }}
        ref={containerRef}
      >
        {/* Album Contents */}
        {albumList.length <= 0 ? getNoMediaLabel() : getAlbums()}
        <CustomSnackbar
          snackbarContent={snackbarContent}
          onClose={() => {
            setSnackbarContent(null);
          }}
        />
      </MDBox>
    </MDBox>
  );
};

export default MediaHubAlbums;
