import { unstable_HistoryRouter as Router } from "react-router-dom";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { AuthProvider } from "./context/AuthProvider";
import { MaterialUIControllerProvider } from "context";
import history from "./history_routing";
import { LicenseInfo } from "@mui/x-license";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
// Adding MUI License
if (process.env.REACT_APP_MUI_X_LICENSE) {
  LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_X_LICENSE);
}

// Removes the /#/ from the url
if (window.location.href.includes("/#/")) {
  const cleanURL = window.location.href.replace("/#/", "/");
  window.location.replace(cleanURL);
}

root.render(
  <Router history={history as any}>
    <MaterialUIControllerProvider>
      <AuthProvider>
        <App />
      </AuthProvider>
    </MaterialUIControllerProvider>
  </Router>
);

reportWebVitals();
