import DashboardContentLayout from "layouts/DashboardContentLayout";
import MediaHubGallery from "./MediaHubGallery";
import MDBox from "components/MDBox";

export const AlbumDetailScreen = () => {
  return (
    <DashboardContentLayout needCardBackground={false}>
      <MDBox pl="20px" pr="10px">
        <MediaHubGallery isGalleryScreen={false} />
      </MDBox>
    </DashboardContentLayout>
  );
};
