import colors from "assets/theme/base/colors";
import MDBadgeDot from "components/MDBadgeDot";
import MDBox from "components/MDBox";
import LineChart from "layouts/Charts/LineChart";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { RoutePath } from "../../../../../constants";
import { useEffect, useState, useMemo, useCallback } from "react";
import { WebServiceStatus } from "utils/services/AppUrls";
import { getVueHistoryForPortalIds } from "pages/dashboard/home/dashboard-home/services/DashboardServices";
import { VueHistory } from "../utils/dashboard_interfaces";
import { filterDateToNanoSecondsString } from "../../vues/vue-grid/helpers/helper_methods";

interface Types {
  labels: string[];
  datasets: {
    label: string;
    tooltipLabelList: string[];
    color:
      | "open"
      | "inprogress"
      | "inreview"
      | "pastdue"
      | "completed"
      | "previousyear"
      | "presentyear"
      | string;
    data: number[];
  }[];
}

function getYearFromRange(
  dateRange: string,
  targetMonth: string
): number | null {
  const [startDate, endDate] = dateRange.split("-");

  const months: { [key: string]: number } = {
    Jan: 0,
    Feb: 1,
    Mar: 2,
    Apr: 3,
    May: 4,
    Jun: 5,
    Jul: 6,
    Aug: 7,
    Sep: 8,
    Oct: 9,
    Nov: 10,
    Dec: 11,
  };

  const startMonth = startDate.trim().split(" ")[0];
  const startYear = parseInt(startDate.trim().split(" ")[1]);
  const endYear = parseInt(endDate.trim().split(" ")[1]);

  const targetMonthIndex = months[targetMonth];

  return targetMonthIndex < months[startMonth] ? endYear : startYear;
}

const defaultChartData: Types = {
  labels: moment.monthsShort(),
  datasets: [
    {
      label: moment().format("MMM"),
      color: "presentyear",
      tooltipLabelList: [],
      data: [],
    },
    {
      label: moment().subtract(1, "month").format("MMM"),
      color: "previousyear",
      tooltipLabelList: [],
      data: [],
    },
  ],
};

const { iVueChartColors } = colors;

function HistoryLineChart({
  selectedCompanyIds,
}: {
  selectedCompanyIds: string[];
}): JSX.Element {
  const navigation = useNavigate();

  const [chartData, setChartData] = useState<Types>(defaultChartData);

  useEffect(() => {
    const fetchVueHistoryForPortalIds = async () => {
      const response = await getVueHistoryForPortalIds(selectedCompanyIds);
      if (response.status === WebServiceStatus.success) {
        const vueHistoryData = response.data;
        if (vueHistoryData !== null) {
          const {
            previousYearVuesData,
            currentYearVuesData,
          }: {
            previousYearVuesData: VueHistory[];
            currentYearVuesData: VueHistory[];
          } = vueHistoryData;
          if (
            previousYearVuesData.length > 0 &&
            currentYearVuesData.length > 0
          ) {
            const monthsList = previousYearVuesData.map((data) => data.month);
            const previousYear = previousYearVuesData.map(
              (data) => data.completedVueCount
            );
            const presentYear = currentYearVuesData.map(
              (data) => data.completedVueCount
            );
            const lastIndexPrevious = previousYearVuesData.length - 1;
            const lastIndexCurrent = currentYearVuesData.length - 1;
            const previousYearLabel = `${previousYearVuesData[0].month} ${previousYearVuesData[0].year} - ${previousYearVuesData[lastIndexPrevious].month} ${previousYearVuesData[lastIndexCurrent].year}`;
            const currentYearLabel = `${currentYearVuesData[0].month} ${currentYearVuesData[0].year} - ${currentYearVuesData[lastIndexPrevious].month} ${currentYearVuesData[lastIndexCurrent].year}`;
            const currentMonthWithYearList = monthsList.map((month) => {
              const year = currentYearVuesData.filter(
                (data) => data.month.toLowerCase() === month.toLowerCase()
              )[0].year;
              return `${month} ${year}`;
            });
            const previousMonthWithYearList = monthsList.map((month) => {
              const year = previousYearVuesData.filter(
                (data) => data.month.toLowerCase() === month.toLowerCase()
              )[0].year;
              return `${month} ${year}`;
            });
            const vueHistory = {
              labels: monthsList,
              datasets: [
                {
                  label: currentYearLabel,
                  color: "presentyear",
                  tooltipLabelList: currentMonthWithYearList,
                  data: presentYear,
                },
                {
                  label: previousYearLabel,
                  color: "previousyear",
                  tooltipLabelList: previousMonthWithYearList,
                  data: previousYear,
                },
              ],
            };
            setChartData(vueHistory);
          }
        }
      }
    };

    fetchVueHistoryForPortalIds();

    return () => {};
  }, [selectedCompanyIds]);

  const { datasets } = chartData;

  const onLineChartClick = useCallback(
    (datasetIndex: number, index: number) => {
      const selectedMonth = chartData.labels[index];
      const selectedYear = getYearFromRange(
        chartData.datasets[datasetIndex].label,
        selectedMonth
      );
      const selectedMoment = moment.utc(
        `${selectedMonth} ${selectedYear}`,
        "MMM YYYY"
      );

      const startDayOfMonth = moment.tz(
        selectedMoment.utc(),
        moment.tz.guess()
      );
      const endDayOfMonth = selectedMoment.endOf("month");

      navigation(RoutePath.vues, {
        state: {
          completedAtStartDate: filterDateToNanoSecondsString(startDayOfMonth),
          completedAtEndDate: filterDateToNanoSecondsString(
            endDayOfMonth,
            true
          ),
          companyIds: selectedCompanyIds,
        },
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [navigation, chartData]
  );

  const getChartLabels = useCallback(() => {
    const labels = datasets.map((data) => {
      const { label, color } = data;
      return (
        <MDBadgeDot
          key={label}
          color={iVueChartColors[color] ? iVueChartColors[color] : color}
          size="sm"
          badgeContent={label}
        />
      );
    });
    return labels;
  }, [datasets]);

  return useMemo(
    () => (
      <LineChart
        title="Vue History"
        description={
          <MDBox display="flex" justifyContent="space-between" mt={1}>
            <MDBox display="flex" ml={-1}>
              {getChartLabels()}
            </MDBox>
          </MDBox>
        }
        chart={chartData}
        onClick={onLineChartClick}
      />
    ),
    [chartData, getChartLabels, onLineChartClick]
  );
}

export default HistoryLineChart;
