import { Divider, Grid, Typography } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { RoutePath } from "../../../../constants";
import DashboardContentLayout from "layouts/DashboardContentLayout";
import CompletedVueList from "pages/dashboard/home/dashboard-home/components/CompletedVueList";
import CustomSelect from "pages/dashboard/components/CustomSelect";
import EscalatedVueList from "pages/dashboard/home/dashboard-home/components/EscalatedVueList";
import Header from "pages/dashboard/home/dashboard-home/components/CustomHeader";
import StatisticCard from "pages/dashboard/home/dashboard-home/components/StatisticCard";
import { Outlet, useNavigate } from "react-router-dom";
import VueGraphs from "./components/VueGraphs";
import moment, { Moment } from "moment";
import { useEffect, useMemo, useReducer, useState } from "react";
import {
  getVuesForDashboard,
  getSummaryFiguresForDashboard,
} from "pages/dashboard/home/dashboard-home/services/DashboardServices";
import { WebServiceStatus } from "utils/services/AppUrls";
import {
  DashboardActionType,
  dashboardSummaryReducer,
  dashboardVuesReducer,
  defaultDashboardSummaryState,
  defaultDashboardVuesState,
} from "./utils/reducer";
import { useAuth } from "context/AuthProvider";
import { Company } from "pages/profile/utils/ProfileInterfaces";
import { PlaceholderComponent } from "pages/dashboard/home/dashboard-home/components/PlaceholderComponent";
import { filterDateToNanoSecondsString } from "../vues/vue-grid/helpers/helper_methods";
import { VueStatus } from "./charts/StatusPieChart";
import { CustomDatePicker } from "@ivueit/vue-engine";

const DashboardHome = () => {
  const navigateToVuesPage = useNavigate();
  const { getAvailablePortalsExcludingReadOnly } = useAuth();
  const availableCompanyList: Company[] = getAvailablePortalsExcludingReadOnly();
  const companyIds = availableCompanyList.map((company) => company.id);
  const [dashboardSummaryState, dispatchDashboardSummaryAction] = useReducer(
    dashboardSummaryReducer,
    defaultDashboardSummaryState
  );
  const [dashboardVueState, dispatchDashboardVuesAction] = useReducer(
    dashboardVuesReducer,
    defaultDashboardVuesState
  );

  /// Today's Date
  const defaultEndDate = moment().format("L");
  /// Default start date (End date - 30 days)
  const defaultStartDate = moment().subtract(30, "days").calendar();
  /// Date Format
  const dateFormat = "MM / DD / YYYY";
  /// This holds the start date
  const [startDate, setStartDate] = useState<Moment | null>(
    moment(defaultStartDate, dateFormat)
  );
  /// This holds the end date
  const [endDate, setEndDate] = useState<Moment | null>(
    moment(defaultEndDate, dateFormat)
  );
  /// Contains the list of companies selected by the user
  const [selectedCompanies, setSelectedCompanies] =
    useState<string[]>(companyIds);

  const [startDateError, setStartDateError] = useState("");
  const [endDateError, setEndDateError] = useState("");

  useEffect(() => {
    setStartDateError(startDate?.isValid() ? "" : "Invalid start date");
    setEndDateError(endDate?.isValid() ? "" : "Invalid end date");
    if (endDate.isBefore(startDate)) {
      setEndDateError("End date should be greater than start date");
    } else {
      const startDateNano = filterDateToNanoSecondsString(startDate);
      const endDateNano = filterDateToNanoSecondsString(endDate, true);

      const fetchVuesForDashboard = async () => {
        // Converting the date to nano seconds
        const response = await getVuesForDashboard(
          selectedCompanies,
          startDateNano,
          endDateNano
        );
        if (response.status === WebServiceStatus.success) {
          const {
            vueStatusCounts,
            completedVueCountByType,
            latestEscalatedAndCompletedVues,
            latestCompletedVues,
          } = response.data;
          dispatchDashboardVuesAction({
            type: DashboardActionType.setDashboardVues,
            payload: {
              vueStatusCounts: vueStatusCounts,
              totalVueCount: vueStatusCounts.totalVues, // TotalVueCount taken from total values
              completedVueCountByType: completedVueCountByType,
              latestEscalatedAndCompletedVues: latestEscalatedAndCompletedVues,
              latestCompletedVues: latestCompletedVues,
            },
          });
        }
      };

      // Fetches the summary figures for dashboard and loads it in the UI
      const fetchSummaryFiguresForDashboard = async () => {
        const response = await getSummaryFiguresForDashboard(
          selectedCompanies,
          startDateNano,
          endDateNano
        );

        var slaCompletionPercentage = 0;
        var under24HourCompletionPercentage = 0;
        var escalatedVueCount = 0;
        if (response.status === WebServiceStatus.success) {
          const data = response.data;
          slaCompletionPercentage = data.slaCompletionPercentage ?? 0;
          under24HourCompletionPercentage =
            data.under24HourCompletionPercentage ?? 0;
          escalatedVueCount = data.escalatedVueCount ?? 0;
        }
        // If the API fails, will load default data
        dispatchDashboardSummaryAction({
          type: DashboardActionType.setSummaryFigures,
          payload: {
            slaCompletionPercentage: slaCompletionPercentage,
            under24HourCompletionPercentage: under24HourCompletionPercentage,
            escalatedVueCount: escalatedVueCount,
          },
        });
      };
      fetchSummaryFiguresForDashboard();
      fetchVuesForDashboard();
    }

    return () => {};
  }, [selectedCompanies, endDate, startDate]);

  const changedSelectedCompanyList = (selectedItems: string[]) => {
    setSelectedCompanies(selectedItems);
  };

  const navigateToVueGrid = (state: { [key: string]: any } = null) => {
    navigateToVuesPage(RoutePath.vues, {
      state: state,
    });
  };

  const getGraph = useMemo(() => {
    const onPieChartClick = (status: VueStatus) => {
      navigateToVueGrid({
        status: status,
        startDate: filterDateToNanoSecondsString(startDate),
        endDate: filterDateToNanoSecondsString(endDate, true),
        companyIds: selectedCompanies,
      });
    };

    const onBarChartClick = (surveyType: string, status: string) => {
      navigateToVueGrid({
        surveyType: surveyType,
        status: status,
        startDate: filterDateToNanoSecondsString(startDate),
        endDate: filterDateToNanoSecondsString(endDate, true),
        companyIds: selectedCompanies,
      });
    };

    const onTotalCountClick = () => {
      navigateToVueGrid({
        startDate: filterDateToNanoSecondsString(startDate),
        endDate: filterDateToNanoSecondsString(endDate, true),
        companyIds: selectedCompanies,
      });
    };

    return (
      <VueGraphs
        selectedCompanyIds={selectedCompanies}
        totalVueCount={dashboardVueState.totalVueCount}
        vueStatusCount={dashboardVueState.vueStatusCounts}
        completedVueCountByType={dashboardVueState.completedVueCountByType}
        onPieChartClick={onPieChartClick}
        onBarChartClick={onBarChartClick}
        onTotalCountClick={onTotalCountClick}
      />
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dashboardVueState.completedVueCountByType,
    dashboardVueState.totalVueCount,
    dashboardVueState.vueStatusCounts,
  ]);

  return (
    <DashboardContentLayout needCardBackground={false}>
      {/* Date Picker and Dropdown */}
      <MDBox mb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={4}>
            <MDBox lineHeight={1}>
              <MDTypography
                variant="h4"
                fontWeight="bold"
                color="black"
                mb={1.6}
                sx={{ fontSize: "22px", letterSpacing: "0" }}
              >
                Created At
              </MDTypography>
            </MDBox>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <CustomDatePicker
                  title="Start Date"
                  defaultDate={moment(defaultStartDate, dateFormat)}
                  onChange={(date) => {
                    setStartDate(date ?? moment(date));
                  }}
                  maximumDate={endDate}
                  errorMessage={startDateError}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <CustomDatePicker
                  title="End Date"
                  defaultDate={moment(defaultEndDate, dateFormat)}
                  minimumDate={startDate}
                  onChange={(date) => {
                    setEndDate(date ?? moment(date));
                  }}
                  errorMessage={endDateError}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={3}>
            <MDBox lineHeight={1}>
              <MDTypography
                variant="h4"
                fontWeight="bold"
                color="black"
                mb={1.6}
                sx={{ fontSize: "22px", letterSpacing: "0" }}
              >
                Portals Displayed
              </MDTypography>
              <MDBox>
                <CustomSelect
                  title="Portals"
                  onChangeValues={changedSelectedCompanyList}
                />
              </MDBox>
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
      {selectedCompanies.length > 0 ? (
        <>
          {/* Statistic data with Cards */}
          <MDBox mb={3}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={4} sx={{ display: "flex" }}>
                <StatisticCard
                  title="SLA Completion Percentage"
                  value={`${dashboardSummaryState.slaCompletionPercentage}%`}
                  onClick={() => {
                    navigateToVueGrid({
                      startDate: filterDateToNanoSecondsString(startDate),
                      endDate: filterDateToNanoSecondsString(endDate, true),
                      companyIds: selectedCompanies,
                      status: "completed",
                    });
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={4} sx={{ display: "flex" }}>
                <StatisticCard
                  title="Percentage of Vues Completed Under 24 Hours"
                  value={`${dashboardSummaryState.under24HourCompletionPercentage}%`}
                  onClick={() => {
                    navigateToVueGrid({
                      startDate: filterDateToNanoSecondsString(startDate),
                      endDate: filterDateToNanoSecondsString(endDate, true),
                      companyIds: selectedCompanies,
                      status: "completed",
                    });
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={4} sx={{ display: "flex" }}>
                <StatisticCard
                  title="Escalations"
                  value={`${dashboardSummaryState.escalatedVueCount}`}
                  onClick={() => {
                    navigateToVueGrid({
                      startDate: filterDateToNanoSecondsString(startDate),
                      endDate: filterDateToNanoSecondsString(endDate, true),
                      escalated: true,
                      companyIds: selectedCompanies,
                    });
                  }}
                />
              </Grid>
            </Grid>
          </MDBox>
          <MDBox mb={1}>{getGraph}</MDBox>
          {/* Recently Escalated Vues Section */}
          <MDBox>
            <Header
              title="Recently Escalated Vues"
              onClick={() => {
                navigateToVueGrid({
                  escalated: true,
                  companyIds: selectedCompanies,
                });
              }}
            />
          </MDBox>
          <MDBox mb={5} pt={3}>
            {dashboardVueState.latestEscalatedAndCompletedVues.length !== 0 ? (
              <EscalatedVueList
                latestEscalatedVues={
                  dashboardVueState.latestEscalatedAndCompletedVues
                }
              />
            ) : (
              <PlaceholderComponent label="There are no recently escalated Vues" />
            )}
          </MDBox>
          <Divider
            sx={{
              backgroundColor: "#4f4f52",
              height: "1px",
              borderWidth: 1,
              opacity: 1,
              flexGrow: 1,
            }}
          />
          {/* Recently Completed Vues Section */}
          <MDBox mb={2} pt={3}>
            <Header
              title="Recently Completed Vues"
              onClick={() => {
                navigateToVueGrid({
                  status: "completed",
                  companyIds: selectedCompanies,
                });
              }}
            />
          </MDBox>
          <MDBox mb={3} pt={3}>
            <CompletedVueList
              completedVues={dashboardVueState.latestCompletedVues}
            />
          </MDBox>
        </>
      ) : (
        <MDBox mt={5} minHeight="52vh" position="relative">
          <Typography
            textAlign="center"
            variant="h5"
            sx={{
              fontWeight: "500",
              color: "#939393",
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50% , -50%)",
            }}
          >
            Please select atleast one portal
          </Typography>
        </MDBox>
      )}
      <Outlet />
    </DashboardContentLayout>
  );
};

export default DashboardHome;
