import { DEFAULT_PAGE_SIZE } from "../../../../../constants";

// All available media types, used in the dropdown
export const availableMediaTypes = ["All", "Photos", "Videos"];
// Defines the defaylt media type, should be included in `availableMediaTypes`
export const defaultMediaType = "All";
// Tooltip message to be dispalyed when no media item is selected
export const NO_MEDIA_SELECTED = "Please select atleast one item.";
// Maximum number of medias that can be downloaded
export const MAXIMUM_DOWNLOAD_ALLOWED = 100;
/// Date Format
export const dateFormat = "MM / DD / YYYY";

/// TODO : Needs to be changes as per the backend enums
export const getMediaType = (type: string) => {
  switch (type.toUpperCase()) {
    case "ALL":
      return 0;
    case "PHOTOS":
      return 1;
    case "VIDEOS":
      return 2;
    default:
      break;
  }
};

export const defaultMetaData = {
  pageSize: DEFAULT_PAGE_SIZE as number,
  pageNumber: 1,
  totalPages: 0,
  totalElements: 0,
};
