export enum PreviewType {
  iframe,
  embed,
  object,
}

interface Props {
  pdfUrl: string;
  title: string;
  previewType?: PreviewType;
}
const Base64PDFViewer = ({
  pdfUrl,
  title,
  previewType = PreviewType.iframe,
}: Props) => {
  const getPreviewComponent = () => {
    switch (previewType) {
      case PreviewType.object:
        return (
          <object
            data={pdfUrl}
            type="application/pdf"
            width="auto"
            height="94vh"
          >
            <p>
              Your browser does not support PDFs. Please download the PDF to
              view it: <a href={pdfUrl}>Download PDF</a>.
            </p>
          </object>
        );
      case PreviewType.embed:
        return (
          <embed
            src={pdfUrl}
            type="application/pdf"
            width="100%"
            height="94vh"
            style={{
              border: "none",
              pointerEvents: "none",
              backgroundColor: "white",
            }}
          />
        );
      case PreviewType.iframe:
        return (
          <iframe
            title={title}
            src={`${pdfUrl}#toolbar=0&navpanes=0;&amp;embedded=true`}
            style={{
              backgroundColor: "white",
              border: "none",
              height: "100%",
              width: "100%",
              maxWidth: "700px",
            }}
          />
        );
    }
  };

  return getPreviewComponent();
};

export default Base64PDFViewer;
