export const notificationListStyle = {
  maxHeight: "60vh",
  minWidth: "330px",
  maxWidth: "330px",
  overflowY: "auto",
  padding: "8px 12px 30px 30px",
  marginRight: "10px",
  "&::-webkit-scrollbar": {
    width: "5px",
    height: "5px",
  },
  "&::-webkit-scrollbar-track": {
    background: "transparent",
    width: "10px",
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "#c7ccd0",
    with: "5px",
    borderRadius: "5px",
  },
  label: {
    display: "flex",
    minWidth: "0",
    maxWidth: "230px",
    "span.MuiFormControlLabel-label": {
      overflow: "hidden",
      lineHeight: "20px",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
    },
  },
  li: {
    borderBottom: "1px solid rgb(233, 234, 237);",
  },
};

export const linkElementStyle = {
  textTransform: "none !important",
  textDecoration: "underline !important",
  cursor: "pointer",
  marginTop: "4px",
};

export const notifyBadge = {
  width: "12px",
  height: "12px",
  borderRadius: "50%",
  background: "rgb(26, 115, 231)",
};

export const notifyIcon = {
  width: "32px",
  height: "32px",
  borderRadius: "50%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  color: "#ffffff",
};

export const notifyIconPrimary = {
  background: "rgb(76, 175, 80)",
};

export const notifyIconDanger = {
  background: "rgb(233, 31, 99)",
};

export const notifyIconInfo = {
  background: "rgb(26, 115, 231)",
};

export const buttonOutlineStyles = {
  minHeight: "unset",
  fontSize: "14px",
  whiteSpace: "nowrap",
  borderColor: "#cccccc",
  textTransform: "none",
};

export const clearBtn = {
  opacity: "0.78",
  marginBottom: "12px",
  "&:hover": {
    opacity: "1",
  },
};

export const secondaryText = {
  "&.MuiTypography-root": {
    fontWeight: 400,
    display: "block",
  },
};

export const listItemTextStyle = {
  maxWidth: "190px",
  ".MuiListItemText-primary": {
    fontSize: "14px",
    fontWeight: "600",
    lineHeight: "20px",
    marginBottom: "2px",
  },
  ".MuiListItemText-secondary": {
    lineHeight: "16px",
  },
};

export const notificationDurationStyle = {
  marginTop: "2px",
  "&.MuiTypography-root": {
    fontWeight: 400,
    display: "block",
  },
};

export const listItemStyle = {
  paddingBottom: "8px",
  marginBottom: "8px",
  cursor: "pointer",
};
