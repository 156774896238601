import DashboardContentLayout from "layouts/DashboardContentLayout";
import { InvoicesDataGrid } from "./components/InvoicesDataGrid";

const Invoices = () => {
  return (
    <DashboardContentLayout needCardBackground={false}>
      <InvoicesDataGrid/>
    </DashboardContentLayout>
  );
}

export default Invoices;
