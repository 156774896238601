// Default page size for the grid
export const DEFAULT_PAGE_SIZE = 100;
// Value used to set the delay, that helps to reduce the frequent state changes & API calls
export const TEXTFIELD_CHANGE_DELAY = 700;
/// Tooltip hover delay. Used in the Vues grid escalation column
export const TOOLTIP_HOVER_DELAY = 300;
// Color code used to identify the required field arsteric
export const REQUIRED_FIELD_COLOR = "#1a73e7";
// Maximum file size for upload (800KB)
export const MAX_FILE_SIZE_IN_MB = 0.78125;
// Maximum length of site number text field
export const MAXIMUM_LENGTH_SITENUMBER = 100;
// Maximum length of instructions text field
export const MAXIMUM_LENGTH_INSTRUCTIONS = 150;
// Maximum number of vues that can be exported at a time (for PDF & PHOTO)
export const VUE_EXPORT_LIMIT = 100;
// Key used to save selected filters
export const SAVED_FILTERS_KEY = "SAVED_FILTERS_KEY";
export const SAVED_SEARCH_KEY = "vueSearchText";
// Generic support error message
export const GENERIC_ERROR_MESSAGE =
  "Something went wrong. If this error persists, please contact iVueit for support";
export const READ_ONLY_DISABLED_MESSAGE = "You don't have permission to perform this action.";
export enum RoutePath {
  // Authentication Routes
  root = "/",
  setupAccount = "/setup-account/handoffs/magiclinks/:email/:requestId/avp/:verificationCode",
  resetPassword = "/reset-password",
  checkInbox = "/check-inbox",
  smsVerification = "/sms-verification",
  changePassword = "/reset-password/handoffs/magiclinks/:email/:requestId/avp/:verificationCode",
  authenticateUser = "/authenticate",
  createVues = "/create-vues",
  // Dashboard - Home Routes
  vues = "/vues",
  vueDashboard = "/dashboard",
  batches = "/dashboard/batches",
  surveys = "/dashboard/surveys",
  siteLists = "/dashboard/site-lists",
  mediahub = "/dashboard/media-hub",
  support = "/dashboard/support",
  // Dashboard - Analytics Routes
  analytics = "/analytics",
  map = "/analytics/map",
  // My Account Routes
  managePlan = "/my-account/manage-plan",
  manageUsers = "/my-account/manage-users",
  invoices = "/my-account/invoices",
  errorPage = "*",
  profile = "/user",
  manageInternalVuerNetwork = "/my-account/manage-internal-vuer-network",
  customersupport = "/support",
  settings = "/settings",
  notifications = "/notifications",
}

export const breadcrumbConfig: {
  [key: string]: {
    routeItems: string[];
    prefix: string;
    itemParentName: string;
    collapseName: string;
    newlyExtendedItem?: string;
  };
} = {
  vues: {
    routeItems: ["vue dashboard", "vues"],
    prefix: "Vue",
    itemParentName: "dashboard",
    collapseName: "dashboard",
    newlyExtendedItem: "Site Detail",
  },
  batches: {
    routeItems: ["vue dashboard"],
    prefix: "",
    itemParentName: "dashboard",
    collapseName: "dashboard",
  },
  surveys: {
    routeItems: ["vue dashboard"],
    prefix: "",
    itemParentName: "dashboard",
    collapseName: "dashboard",
  },
  "media-hub": {
    routeItems: ["vue dashboard", "media-hub"],
    prefix: "",
    itemParentName: "dashboard",
    collapseName: "dashboard",
  },
  "create-vues": {
    routeItems: ["create vues"],
    prefix: "",
    itemParentName: "",
    collapseName: "",
  },
  "site-lists": {
    routeItems: ["vue dashboard"],
    prefix: "",
    itemParentName: "",
    collapseName: "",
    newlyExtendedItem: "Site Detail",
  },
  dashboard: {
    routeItems: ["vue dashboard"],
    prefix: "",
    itemParentName: "",
    collapseName: "dashboard",
  },
  settings: {
    routeItems: ["settings"],
    prefix: "",
    itemParentName: "",
    collapseName: "",
  },
  support: {
    routeItems: ["support"],
    prefix: "",
    itemParentName: "",
    collapseName: "",
  },
  "manage-users": {
    routeItems: ["my-account"],
    prefix: "",
    itemParentName: "my-account",
    collapseName: "my-account",
  },
  "manage-internal-vuer-network": {
    routeItems: ["my-account"],
    prefix: "",
    itemParentName: "my-account",
    collapseName: "my-account",
  },
  invoices: {
    routeItems: ["my-account", "invoices"],
    prefix: "Invoice",
    itemParentName: "my-account",
    collapseName: "my-account",
  },
  "my-account": {
    routeItems: ["my-account"],
    prefix: "",
    itemParentName: "",
    collapseName: "my-account",
  },
};
