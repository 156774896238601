import { searchBarStyle } from "../../vues/styles/VueStyles";
import { TextField, InputAdornment, Tooltip, Grid } from "@mui/material";
import { Search } from "@mui/icons-material";
import {
  CustomDatePicker,
  CustomDialogBox,
  CustomDropdown,
  CustomDropdownOption,
  downloadFileToDevice,
} from "@ivueit/vue-engine";
import { useCallback, useEffect, useRef, useState } from "react";
import MDBox from "components/MDBox";
import { Moment } from "moment";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import { StyledButtonWithIcon } from "../../site-lists/components/SiteListActionBar";
import {
  mdiEye,
  mdiEyeOff,
  mdiImageMove,
  mdiTag,
  mdiTrayArrowDown,
} from "@mdi/js";
import { tooltipStyles } from "../../site-lists/styles/site_list_style";
import PhotoThumbnail from "./PhotoThumbnail";
import { AddToAlbumDialogContent } from "./AddToAlbumDialogContent";
import { useNavigate } from "react-router";
import {
  DEFAULT_PAGE_SIZE,
  GENERIC_ERROR_MESSAGE,
  RoutePath,
} from "../../../../../constants";
import { MediaContainer } from "./MediaContainer";
import { TEXTFIELD_CHANGE_DELAY } from "./../../../../../constants";
import {
  MAXIMUM_DOWNLOAD_ALLOWED,
  NO_MEDIA_SELECTED,
  availableMediaTypes,
  defaultMediaType,
  defaultMetaData,
  getMediaType,
} from "../utils/constants";
import { filterDateToNanoSecondsString } from "../../vues/vue-grid/helpers/helper_methods";
import { CustomIndicator } from "pages/components/CustomIndicator";
import {
  getClientMediaInfo,
  hideMediaItems,
  removesMediasFromAlbum,
} from "../services/MediaHubServices";
import { WebServiceStatus } from "utils/services/AppUrls";
import {
  ClientMediaAPIMetaData,
  ClientMediaInfo,
  MonthBasedMedias,
} from "../utils/interfaces";
import { useLocation } from "react-router-dom";
import { TagPhotoDialogContent } from "../../vues/vue_detail/components/TagPhotoDialogContent";
import { ConfirmationDialog } from "../../site-lists/components/ConfirmationDialog";
import CustomSnackbar, {
  CustomSnackbarContent,
} from "pages/components/CustomSnackbar";
import { TagItem } from "pages/settings/interfaces/interfaces";
import {
  generatePresignedPhotoFileURL,
  generatePresignedVideoFileURL,
} from "../../vues/services/VueServices";
import moment from "moment";
import VideoThumbnail from "../../vues/vue_detail/components/VideoThumbnail";
import { alphanumericSort } from "utils/helpers/extensions";

const MediaHubGallery = ({ isGalleryScreen }: { isGalleryScreen: boolean }) => {
  const navigate = useNavigate();
  const location = useLocation();
  /// For scrolling
  const containerRef = useRef<HTMLDivElement>(null);
  const selectedAlbumId = location.state?.albumId ?? "";
  const [showLoader, setShowLoader] = useState<boolean>(false);
  const [selectedMediaType, setSelectedMediaType] =
    useState<string>(defaultMediaType);
  const [searchText, setSearchText] = useState<string>("");
  const [searchTextChanged, setSearchTextChanged] = useState<boolean>(false);
  const [startDate, setStartDate] = useState<Moment | null>(null);
  const [endDate, setEndDate] = useState<Moment | null>(null);
  const [metaValues, setMetaValues] =
    useState<ClientMediaAPIMetaData>(defaultMetaData);
  // Decides whether the grid items can be selected or not
  const [enableSelection, setEnableSelection] = useState<boolean>(false);
  const [monthBasedMediasList, setMonthBasedMediasList] = useState<
    MonthBasedMedias[]
  >([]);
  const listOfMedias = monthBasedMediasList.flatMap(
    (media) => media.mediasList
  );
  const [showAddToAlbumDialog, setShowAddToAlbumDialog] =
    useState<boolean>(false);
  const [showRemoveFromAlbumDialog, setShowRemoveFromAlbumDialog] =
    useState<boolean>(false);
  const [showTagMediaDialog, setShowTagMediaDialog] = useState<boolean>(false);
  // Contains the media items selected by the user
  const [selectedMediaItems, setSelectedMediaItems] = useState<
    ClientMediaInfo[]
  >([]);
  const [dateError, setDateError] = useState({
    startDate: "",
    endDate: "",
  });
  const [snackbarContent, setSnackbarContent] =
    useState<CustomSnackbarContent | null>(null);

  const handleDropDownClick = (newValue: string) => {
    setSelectedMediaType(newValue);
  };

  const getSearchTextfield = () => {
    return (
      <TextField
        fullWidth
        value={searchText}
        placeholder="Search"
        sx={searchBarStyle}
        InputLabelProps={{ shrink: true }}
        onChange={(event) => {
          setSearchTextChanged(true);
          setSearchText(event.target.value);
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <Search fontSize="medium" sx={{ color: "#344767" }} />
            </InputAdornment>
          ),
        }}
      />
    );
  };

  /// Handles the onChange of startDate and endDate
  const handleStartDateChange = (newDate: Moment | null) => {
    const error = newDate
      ? (endDate && newDate && newDate > endDate) || !newDate.isValid()
        ? "Invalid start date."
        : ""
      : "";
    setDateError((prev) => ({
      startDate: error,
      endDate: "",
    }));
    const date = newDate !== null && newDate.isValid() ? newDate : null;
    setStartDate(date);
  };

  const handleEndDateChange = (newDate: Moment | null) => {
    const error = newDate
      ? (startDate && startDate > newDate) || !newDate.isValid()
        ? "Invalid end date."
        : ""
      : "";
    setDateError((prev) => ({
      startDate: "",
      endDate: error,
    }));
    const date = newDate !== null && newDate.isValid() ? newDate : null;
    setEndDate(date);
  };

  const getDatePickers = () => {
    const helperTextStyle = {
      "p.MuiFormHelperText-root": {
        position: "absolute",
        bottom: "-16px",
        left: "5px",
      },
    };
    return (
      <MDBox ml={3} display="flex">
        <MDBox ml={1} position="relative" sx={helperTextStyle}>
          <CustomDatePicker
            title="Start Date"
            defaultDate={null}
            minimumDate={startDate}
            onChange={handleStartDateChange}
            errorMessage={dateError.startDate}
          />
        </MDBox>
        <MDBox ml={1} position="relative" sx={helperTextStyle}>
          <CustomDatePicker
            title="End Date"
            defaultDate={null}
            minimumDate={endDate}
            onChange={handleEndDateChange}
            errorMessage={dateError.endDate}
          />
        </MDBox>
      </MDBox>
    );
  };

  const getLeftTopbarItems = () => {
    return (
      <MDBox display="flex" alignItems="end" flexGrow="1">
        {getSearchTextfield()}
        <CustomDropdown
          title={"Media Type"}
          selectedItem={{
            value: selectedMediaType,
            displayTitle: selectedMediaType,
          }}
          availableValues={availableMediaTypes.map((type) => {
            return {
              value: type,
              displayTitle: type,
            };
          })}
          onChange={(newValue: CustomDropdownOption) => {
            handleDropDownClick(newValue.value);
          }}
          prefixTitle={true}
          minWidth="175px"
        />
        {getDatePickers()}
      </MDBox>
    );
  };

  const getRightTopBarItems = () => {
    return (
      <MDBox display="flex" alignItems="end" flexGrow="1" pl="10px">
        <MDBox mr={2.8} display="flex" flexDirection="column" alignItems="end">
          <MDTypography
            variant="button"
            color="textLight"
            fontWeight="regular"
            sx={{ lineHeight: "16px", mb: "4px" }}
          >
            {enableSelection ? "Media Selected" : "Media Total"}
          </MDTypography>
          <MDTypography variant="h5">
            {enableSelection
              ? `${selectedMediaItems.length}/${metaValues.totalElements}`
              : `${metaValues.totalElements}`}
          </MDTypography>
        </MDBox>
        {enableSelection ? (
          <MDButton
            variant="outlined"
            color="primary"
            sx={{
              height: "36px",
              borderColor: "#c7ccd0",
              fontSize: "14px",
              backgroundColor: "#ffffff",
            }}
            onClick={() => {
              setEnableSelection(false);
              setSelectedMediaItems([]);
            }}
          >
            CANCEL
          </MDButton>
        ) : (
          <MDButton
            variant="contained"
            color="info"
            sx={{
              fontSize: "14px",
            }}
            onClick={() => {
              setEnableSelection(true);
            }}
            disabled={false}
          >
            SELECT
          </MDButton>
        )}
      </MDBox>
    );
  };

  const updateVisibilityStatus = async (toHide: boolean) => {
    setShowLoader(true);
    const selectedMediaIds = selectedMediaItems.map((media) => media.id);
    const response = await hideMediaItems(toHide, selectedMediaIds);
    if (response.status === WebServiceStatus.success) {
      const data = response.data.value as boolean;
      if (data) {
        setSnackbarContent({
          title: "Success",
          message: "Updated the visibility status successfully.",
          isError: false,
        });
        fetchClientMediaInfoFromServer(false);
      }
    } else {
      setSnackbarContent({
        title: "Attention!",
        message: `Unable to update the visibility status : ${response.error}`,
        isError: true,
      });
    }
    setShowLoader(false);
  };

  const getHideMediaButton = () => {
    // Decides whether to disable the button or not
    const disableActionBar = selectedMediaItems.length <= 0;
    // Whether having hidden items
    const hasHiddenItems = selectedMediaItems.some((media) => media.clientHide);
    // Checks whether having visible items
    const hasVisibleItems = selectedMediaItems.some(
      (media) => !media.clientHide
    );
    let disableHideButton = false;
    let tooltipMessage = "";
    let icon = mdiEyeOff;
    let title = "Hide";
    // Handling the button enability
    if (disableActionBar) {
      disableHideButton = true;
      tooltipMessage = NO_MEDIA_SELECTED;
    } else if (hasHiddenItems && hasVisibleItems) {
      disableHideButton = true;
      // Selected items contains both hidden items and visible items
      tooltipMessage = "Please make sure all media items have the same status.";
    } else if (hasHiddenItems) {
      icon = mdiEye;
      title = "Unhide";
    }

    return (
      <Tooltip
        title={tooltipMessage}
        arrow
        placement="right"
        componentsProps={{
          tooltip: {
            sx: tooltipStyles,
          },
        }}
        disableHoverListener={!disableHideButton}
      >
        <span>
          <StyledButtonWithIcon
            iconPath={icon}
            disabled={disableHideButton}
            onClick={() => {
              if (hasHiddenItems) {
                updateVisibilityStatus(false);
              } else {
                updateVisibilityStatus(true);
              }
            }}
          >
            {title}
          </StyledButtonWithIcon>
        </span>
      </Tooltip>
    );
  };

  const downloadSelectedMedias = () => {
    selectedMediaItems.forEach(async (media) => {
      setShowLoader(true);
      const response =
        media.mediaType.toLowerCase() === "video"
          ? await generatePresignedVideoFileURL(media.id)
          : await generatePresignedPhotoFileURL(media.thumbnailFileId);
      if (response.status === WebServiceStatus.success) {
        const { url } = response.data;
        try {
          await downloadFileToDevice(url, media.filename, media.mimeType);
        } catch (error) {
          setSnackbarContent({
            title: "Attention!",
            message: "Failed to download the media",
            isError: true,
          });
        }
      } else {
        setSnackbarContent({
          title: "Attention!",
          message: "Failed to fetch the media URL",
          isError: true,
        });
      }
      setShowLoader(false);
    });
  };

  const getDownloadButton = () => {
    // Decides whether to disable the button or not
    const hasHiddenMedias = selectedMediaItems.some(
      (media) => media.clientHide || media.adminHide
    );
    const disableActionBar = selectedMediaItems.length <= 0 || hasHiddenMedias;
    const didSelectMaximum =
      selectedMediaItems.length > MAXIMUM_DOWNLOAD_ALLOWED;
    const disableDownloadButton = disableActionBar || didSelectMaximum;
    let tooltipMessage = "";
    if (selectedMediaItems.length > 0 && hasHiddenMedias) {
      tooltipMessage = "Hidden medias cannot be downloaded";
    } else if (disableActionBar) {
      tooltipMessage = NO_MEDIA_SELECTED;
    } else if (didSelectMaximum) {
      tooltipMessage = `This action is unavailable because more than ${MAXIMUM_DOWNLOAD_ALLOWED} selections have been made.`;
    }
    return (
      <Tooltip
        title={tooltipMessage}
        arrow
        placement="right"
        componentsProps={{
          tooltip: {
            sx: tooltipStyles,
          },
        }}
        disableHoverListener={!disableDownloadButton}
      >
        <span>
          <StyledButtonWithIcon
            iconPath={mdiTrayArrowDown}
            disabled={disableDownloadButton}
            onClick={downloadSelectedMedias}
          >
            Download
          </StyledButtonWithIcon>
        </span>
      </Tooltip>
    );
  };

  const getTagButton = () => {
    // Disables the action bar when no media items are selected
    const disableActionBar = selectedMediaItems.length <= 0;
    return (
      <Tooltip
        title={NO_MEDIA_SELECTED}
        arrow
        placement="right"
        componentsProps={{
          tooltip: {
            sx: tooltipStyles,
          },
        }}
        disableHoverListener={!disableActionBar}
      >
        <span>
          <StyledButtonWithIcon
            iconPath={mdiTag}
            onClick={() => {
              setShowTagMediaDialog(true);
            }}
            disabled={disableActionBar}
          >
            Tag
          </StyledButtonWithIcon>
        </span>
      </Tooltip>
    );
  };

  const getAddToAlbumButton = () => {
    // Disables the action bar when no media items are selected
    const disableActionBar = selectedMediaItems.length <= 0;

    return (
      <Tooltip
        title={NO_MEDIA_SELECTED}
        arrow
        placement="right"
        componentsProps={{
          tooltip: {
            sx: tooltipStyles,
          },
        }}
        disableHoverListener={!disableActionBar}
      >
        <span>
          <StyledButtonWithIcon
            iconPath={mdiImageMove}
            onClick={() => {
              setShowAddToAlbumDialog(true);
            }}
            disabled={disableActionBar}
          >
            Add to Album
          </StyledButtonWithIcon>
        </span>
      </Tooltip>
    );
  };

  const removeMediaFromAlbum = async () => {
    setShowLoader(true);
    const mediaIDs = selectedMediaItems.map((media) => media.id);
    const response = await removesMediasFromAlbum(selectedAlbumId, mediaIDs);
    if (response.status === WebServiceStatus.success) {
      setSnackbarContent({
        title: "Success",
        message: "Media deleted successfully.",
        isError: false,
      });
      setSelectedMediaItems([]);
      // Reloading the page
      fetchClientMediaInfoFromServer(false);
    } else {
      setSnackbarContent({
        title: "Attention!",
        message: response.error ?? GENERIC_ERROR_MESSAGE,
        isError: true,
      });
    }
    setShowRemoveFromAlbumDialog(false);
    setShowLoader(false);
  };

  const getRemoveFromAlbumConfirmationDialog = () => {
    return (
      <CustomDialogBox
        title={"Remove from Album"}
        width="450px"
        openDialog={showRemoveFromAlbumDialog}
      >
        <ConfirmationDialog
          message={
            "Are you sure you want to remove the selected items from this Album? This action cannot be undone."
          }
          buttonName={"Yes, Remove"}
          onClose={() => {
            setShowRemoveFromAlbumDialog(false);
          }}
          onClick={removeMediaFromAlbum}
        />
      </CustomDialogBox>
    );
  };

  const getRemoveFromAlbumButton = () => {
    const disableActionBar = selectedMediaItems.length <= 0;

    return (
      !isGalleryScreen && (
        <MDBox mr={1.6} mt="25px">
          <Tooltip
            title={NO_MEDIA_SELECTED}
            arrow
            placement="right"
            componentsProps={{
              tooltip: {
                sx: tooltipStyles,
              },
            }}
            disableHoverListener={!disableActionBar}
          >
            <span>
              <StyledButtonWithIcon
                iconPath={mdiImageMove}
                onClick={() => {
                  setShowRemoveFromAlbumDialog(true);
                }}
                disabled={disableActionBar}
                color="error"
              >
                Remove from Album
              </StyledButtonWithIcon>
            </span>
          </Tooltip>
        </MDBox>
      )
    );
  };

  const getActionBar = () => {
    return (
      <MDBox display="flex" alignItems="end" flexGrow="1">
        <MDBox mr={1.6} mt="25px">
          {getTagButton()}
        </MDBox>
        <MDBox mr={1.6} mt="25px">
          {getHideMediaButton()}
        </MDBox>
        <MDBox mr={1.6} mt="25px">
          {getDownloadButton()}
        </MDBox>
        <MDBox mr={1.6} mt="25px">
          {getAddToAlbumButton()}
        </MDBox>
        {getRemoveFromAlbumButton()}
      </MDBox>
    );
  };

  const getNoMediaLabel = () => {
    return (
      <MDBox display="flex" justifyContent="center" mt="15vh">
        <MDTypography>
          {showLoader ? "Loading..." : "No media available."}
        </MDTypography>
      </MDBox>
    );
  };

  const getMediaItems = () => {
    return monthBasedMediasList.map((media) => {
      return (
        media.mediasList.length > 0 && (
          <MDBox>
            <MDBox mb={1.8} mt={1.8}>
              <MDTypography variant="h5">{media.monthWithYear}</MDTypography>
            </MDBox>
            <Grid container spacing={2}>
              {media.mediasList.map((media, index) => {
                const selectedIndex = selectedMediaItems.findIndex(
                  (selectedItem: ClientMediaInfo) =>
                    selectedItem.id === media.id
                );
                const isSelected = selectedIndex !== -1;
                return (
                  <Grid
                    item
                    xs={6}
                    md={4}
                    key={index}
                    sx={{
                      display: "flex !important",
                      flexDirection: "column !important",
                      img: { height: "300px !important" },
                    }}
                  >
                    <MediaContainer
                      clientMediaInfo={media}
                      isSelected={isSelected}
                      enableSelection={enableSelection}
                      handleMediaClick={(e) => {
                        e.stopPropagation();
                        if (enableSelection) {
                          // e.shiftKey decides whether the shift key is pressed or not
                          handleMediaSelection(
                            selectedIndex,
                            media,
                            e.shiftKey
                          );
                        } else {
                          navigate(`${RoutePath.vues}/${media.vueCanonicalId}`);
                        }
                      }}
                    >
                      {media.mediaType.toLowerCase() === "video" ? (
                        <VideoThumbnail
                          thumbnailFileId={media.thumbnailFileId}
                          imageWidth={"100%"}
                          imageHeight={"42vh"}
                        />
                      ) : (
                        <PhotoThumbnail fileID={media.thumbnailFileId} />
                      )}
                    </MediaContainer>
                  </Grid>
                );
              })}
            </Grid>
          </MDBox>
        )
      );
    });
  };

  // Handles media selection
  const handleMediaSelection = (
    indexOfSelectedMedia: number, // Index of item in the selected item array
    selectedMedia: ClientMediaInfo,
    shiftPressed: boolean
  ) => {
    // Handles multi selection with shiftKey
    if (shiftPressed) {
      handleMultiSelectionWithShiftKey(selectedMedia);
    } else {
      // Handles selection with click
      let selectedItems = [...selectedMediaItems];
      if (indexOfSelectedMedia !== -1) {
        // Deselecting the item
        selectedItems.splice(indexOfSelectedMedia, 1);
      } else {
        // Selecting a new item
        selectedItems.push(selectedMedia);
      }
      setSelectedMediaItems(selectedItems);
    }
  };

  /// Handles multi selection with shiftKey
  const handleMultiSelectionWithShiftKey = (selectedMedia: ClientMediaInfo) => {
    const indexOfCurrentItem = listOfMedias.findIndex(
      (mediaItem) => mediaItem.id === selectedMedia.id
    );

    let lastSelectedItemIndex = indexOfCurrentItem;
    const totalSelectedItems = selectedMediaItems.length;
    let newlySelectedItems: ClientMediaInfo[] = [];
    if (totalSelectedItems > 0) {
      const idOfLastItem = selectedMediaItems[totalSelectedItems - 1].id;
      lastSelectedItemIndex = listOfMedias.findIndex(
        (mediaItem) => mediaItem.id === idOfLastItem
      );
      let startIndex = lastSelectedItemIndex;
      let endIndex = indexOfCurrentItem;
      // Alows to select in reverse mode
      if (indexOfCurrentItem < lastSelectedItemIndex) {
        startIndex = indexOfCurrentItem;
        endIndex = lastSelectedItemIndex;
      }
      // Finding new items, and excluding already selected items
      const newItems = listOfMedias
        .slice(startIndex, endIndex + 1)
        // Excluding already selected items
        .filter(
          (media) =>
            !selectedMediaItems.some((selected) => selected.id === media.id)
        );
      newlySelectedItems = [...selectedMediaItems, ...newItems];
    } else {
      // No items selected, so selecting the current item
      newlySelectedItems = [selectedMedia];
    }
    setSelectedMediaItems(newlySelectedItems);
  };

  const getUniqueAddedTagsList = (selectedMediaItems: ClientMediaInfo[]) => {
    var uniqueTagsList: TagItem[] = [];
    const listOfSelectedMediaTags = selectedMediaItems.flatMap(
      (media) => media.appliedMediaTags as TagItem[]
    );
    listOfSelectedMediaTags.forEach((tagItem) => {
      const listOftagIDs = uniqueTagsList.map((tag) => tag.id);
      /// Comparing the tag IDs & fetching only the uniques tag items
      if (!listOftagIDs.includes(tagItem.id)) {
        uniqueTagsList.push(tagItem);
      }
    });
    return uniqueTagsList;
  };

  /// Tag Media dialog
  const getTagMediaDialog = () => {
    return (
      <CustomDialogBox
        title="Tag Media Selections"
        width="440px"
        openDialog={showTagMediaDialog}
      >
        <TagPhotoDialogContent
          isMediaHubScreen
          previouslyAddedTags={getUniqueAddedTagsList(selectedMediaItems)}
          cancelButtonClick={() => {
            setShowTagMediaDialog(false);
          }}
          saveButtonClick={(selectedTagsList: string[]) => {
            fetchClientMediaInfoFromServer(false);
            setShowTagMediaDialog(false);
            setSnackbarContent({
              title: "Success",
              message: "Updated the tags successfully.",
              isError: false,
            });
          }}
          mediaIdsList={selectedMediaItems.map((media) => media.id)}
          stepStateId={""}
          allCompanyMediaTags={[]}
        />
      </CustomDialogBox>
    );
  };

  const getAddToAlbumDialog = () => {
    return (
      <CustomDialogBox
        title="Move Media Selections"
        width="302px"
        openDialog={showAddToAlbumDialog}
      >
        <AddToAlbumDialogContent
          handleCancelClick={(completed) => {
            if (completed) {
              setSnackbarContent({
                title: "Success",
                message: "Medias added successfully.",
                isError: false,
              });
            }
            setShowAddToAlbumDialog(false);
          }}
          selectedMediaItems={selectedMediaItems}
        />
      </CustomDialogBox>
    );
  };

  // Check for the valid start date and end date
  const isValidParameter = () => {
    const isValid =
      (startDate?.isValid() && endDate?.isValid()) ||
      (!startDate?.isValid() && !endDate?.isValid());
    return isValid && !dateError.startDate && !dateError.endDate;
  };

  const getParameterString = (isScrolling: boolean) => {
    // Default page number will be 1 if not scrolling
    var newPageNumber = isScrolling ? metaValues.pageNumber + 1 : 1;
    var pageSize = DEFAULT_PAGE_SIZE;
    var parameterString = `pageSize=${pageSize}&pageNumber=${newPageNumber}`;
    // Appending album ID only if it's album details page
    if (!isGalleryScreen && selectedAlbumId.isNotEmpty()) {
      parameterString += `&albumId=${selectedAlbumId}`;
    }
    /// Appending Site Name
    if (searchText.isNotEmpty()) {
      parameterString += `&vueQueryString=${searchText}`;
    }
    /// Appending the media type
    if (selectedMediaType.isNotEmpty()) {
      parameterString += `&mediaType=${getMediaType(selectedMediaType)}`;
    }
    /// Appending created start & end dates
    if (startDate !== null && endDate !== null) {
      const formattedStartDate = filterDateToNanoSecondsString(startDate);
      const formattedEndDate = filterDateToNanoSecondsString(endDate, true);
      parameterString += `&createdAtRange.startTime=${formattedStartDate}&createdAtRange.endTime=${formattedEndDate}`;
    }
    return parameterString;
  };

  const groupPhotosByMonth = (
    medias: ClientMediaInfo[]
  ): MonthBasedMedias[] => {
    var groupedMedias: { [key: string]: ClientMediaInfo[] } = {};
    var monthBasedMedias: MonthBasedMedias[] = [];
    /// Creating an object which has monthWithYear as KEY &
    /// corresponding list of medias as VALUE
    medias.map((media) => {
      const key = media.capturedAt.formatUTCNanoSecondsToString("MMMM YYYY");
      /// Checking if there is an array with medias is already created for the month
      /// If not, initializing an array & pushing the media. Or else, just pushing the item
      if (!groupedMedias[key]) {
        groupedMedias[key] = [];
      }
      groupedMedias[key].push(media);
    });
    /// Constructing array from the above created object
    Object.keys(groupedMedias).map((monthWithYear) => {
      const item = {
        monthWithYear: monthWithYear,
        mediasList: groupedMedias[monthWithYear].sort((a, b) =>
          alphanumericSort(b.capturedAt, a.capturedAt)
        ),
      };
      monthBasedMedias.push(item);
    });
    return monthBasedMedias.sort((a, b) => {
      return (
        moment(b.monthWithYear, "MMMM YYYY").valueOf() -
        moment(a.monthWithYear, "MMMM YYYY").valueOf()
      );
    });
  };

  const fetchClientMediaInfoFromServer = useCallback(
    async (isScrolling: boolean) => {
      if (isValidParameter()) {
        setShowLoader(true);
        const parameters = getParameterString(isScrolling);
        const response = await getClientMediaInfo(parameters);
        var mediaItemsFromServer: ClientMediaInfo[] = [];
        var groupedMediasByMonth: MonthBasedMedias[] = [];
        if (response.status === WebServiceStatus.success) {
          const {
            mediaFiles,
            meta,
          }: { mediaFiles: ClientMediaInfo[]; meta: ClientMediaAPIMetaData } =
            response.data;
          const filteredMedias = mediaFiles.filter((media) => !media.adminHide);
          mediaItemsFromServer = filteredMedias;
          if (meta) {
            setMetaValues({
              pageSize: Number(meta.pageSize),
              pageNumber: Number(meta.pageNumber),
              totalPages: Number(meta.totalPages),
              totalElements: Number(meta.totalElements),
            });
          }
        } else {
          setMonthBasedMediasList([]);
          setSnackbarContent({
            title: "Attention!",
            message: `Unable to load medias: ${response.error}`,
            isError: true,
          });
        }
        if (isScrolling) {
          const existingMediaItems = monthBasedMediasList.flatMap(
            (medias) => medias.mediasList
          );
          /// Appending the new set of medias from the server with the existing medias
          /// then grouped it month wise
          const mediasList = [...mediaItemsFromServer, ...existingMediaItems];
          groupedMediasByMonth = groupPhotosByMonth(mediasList);
        } else {
          groupedMediasByMonth = groupPhotosByMonth(mediaItemsFromServer);
        }
        setMonthBasedMediasList(groupedMediasByMonth);
        setShowLoader(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [getParameterString]
  );

  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      const handleOnRowsScrollEnd = async () => {
        const { scrollTop, scrollHeight, clientHeight } = container;
        const hasReachedEnd =
          Math.abs(scrollHeight - clientHeight - scrollTop) < 1;
        const shouldScroll =
          listOfMedias.length < metaValues.totalElements &&
          metaValues.pageNumber < metaValues.totalPages;
        if (shouldScroll && hasReachedEnd) {
          await fetchClientMediaInfoFromServer(true);
        }
      };
      container.addEventListener("scroll", handleOnRowsScrollEnd);
      return () => {
        container.removeEventListener("scroll", handleOnRowsScrollEnd);
      };
    }
  }, [listOfMedias, metaValues]);

  useEffect(() => {
    fetchClientMediaInfoFromServer(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedMediaType, startDate, endDate]);

  useEffect(() => {
    if (searchTextChanged) {
      // performs filter on given field and text
      const performMediaSearch = (searchText: string) => {
        setSearchText(searchText);
        fetchClientMediaInfoFromServer(false);
      };
      /// The method delays the callback for 700 millseconds
      const delaySearchAction = setTimeout(() => {
        performMediaSearch(searchText.trim());
      }, TEXTFIELD_CHANGE_DELAY);
      return () => clearTimeout(delaySearchAction);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText]);

  return (
    <MDBox>
      {showLoader && <CustomIndicator />}
      {showTagMediaDialog && getTagMediaDialog()}
      {showAddToAlbumDialog && getAddToAlbumDialog()}
      {showRemoveFromAlbumDialog && getRemoveFromAlbumConfirmationDialog()}
      {/* Top bar items */}
      <MDBox display="flex" alignItems="start" pb={1.4}>
        <MDBox display="flex" flexGrow={1}>
          <MDBox>
            {enableSelection ? getActionBar() : getLeftTopbarItems()}
          </MDBox>
          <MDBox display="flex" ml="auto" mt="10px">
            {getRightTopBarItems()}
          </MDBox>
        </MDBox>
      </MDBox>
      <MDBox
        sx={{ height: "calc(100vh - 234px)", overflow: "auto" }}
        mx="-5px"
        px="5px"
        ref={containerRef}
      >
        {/* Gallery Content */}
        {listOfMedias.length <= 0 ? getNoMediaLabel() : getMediaItems()}
        <CustomSnackbar
          snackbarContent={snackbarContent}
          onClose={() => {
            setSnackbarContent(null);
          }}
        />
      </MDBox>
    </MDBox>
  );
};

export default MediaHubGallery;
