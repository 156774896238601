import { ReactNode, useEffect } from "react";
import { WebServiceStatus } from "utils/services/AppUrls";
import { LocalStorageKeys, useAuth } from "context/AuthProvider";
import { isTokenValid, refreshToken } from "pages/authentication/services/AuthenticationServices";
import moment, { Moment } from "moment";


/// Timer to logout automatically. Timer should be in milli seconds
/// (minutes * seconds * 1000); | To change, update the first number, in minutes
const TOKEN_REFRESH_TIMER = 5 * 60 * 1000;
const AUTO_LOGOUT_DAYS = 30;

const SessionManager = ({ children }: { children: ReactNode }) => {
  const { storeAuthToken, logOut } = useAuth();


  useEffect(() => {
    ///////////// AUTO LOGOUT ///////////////////////
    const checkForSessionExpiry = () => {
      const loginTime: Moment = moment(window.localStorage.getItem(LocalStorageKeys.loginTime));
      const currentTime: Moment = moment()
      const difference = currentTime.diff(loginTime, 'days')
      if (difference >= AUTO_LOGOUT_DAYS) {
        logOut()
      } else {
        handleRefreshToken()
      }
    }
    //////////// TOKEN REFRESH ///////////////////
    const handleRefreshToken = async () => {
      const storedAuthToken: string = window.localStorage.getItem(LocalStorageKeys.authToken);
      var authToken: string | null = storedAuthToken.replace(/"/g, "");
      if (authToken === null) {
        return;
      }
      const response = await isTokenValid(authToken);
      if (response.status !== WebServiceStatus.success) {
        const response = await refreshToken(authToken);
        if (response.status === WebServiceStatus.success) {
          const newAuthToken = response.data.body as string;
          storeAuthToken(newAuthToken);
        }
      }
    };

    /// Check for Session Expiry Refresh token once in every 10 minutes
    const interval = setInterval(() => {
      checkForSessionExpiry();
    }, TOKEN_REFRESH_TIMER);

    return () => {
      clearInterval(interval);
    };
  }, [logOut, storeAuthToken]);

  return <>
    {children}
  </>;
};

export default SessionManager;
