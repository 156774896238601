import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardContentLayout from "layouts/DashboardContentLayout";
import ComingSoon from "pages/components/ComingSoon";
import Icon from "@mdi/react";
import { IconButton } from "@mui/material";
import { mdiInformationOutline } from "@mdi/js";
import { useState } from "react";
import InfoDialogContent from "./components/InfoDialogContent";
import { CustomDialogBox } from "@ivueit/vue-engine";

const Support = () => {
  const [openInfoDialog, setopenInfoDialogDialog] = useState<boolean>(false);

  const getInfoDialog = () => {
    return (
      <CustomDialogBox
        title={"Version Information"}
        width="420px"
        openDialog={openInfoDialog}
      >
        <InfoDialogContent
          closeDialog={() => {
            setopenInfoDialogDialog(false);
          }}
        />
      </CustomDialogBox>
    );
  };

  return (
    <DashboardContentLayout needCardBackground={false}>
      {openInfoDialog && getInfoDialog()}
      <ComingSoon />
      {/* TODO: REMOVE THE VERSION NUMBER ONCE IT'S READY FOR RELEASE */}
      <MDBox display="flex" alignItems="center" py={1} ml={1}>
        <MDTypography
          sx={{
            fontWeight: 500,
            fontSize: "14px",
          }}
        >{`Beta ${process.env.REACT_APP_VERSION}`}</MDTypography>
        <IconButton
          color="primary"
          onClick={() => {
            setopenInfoDialogDialog(true);
          }}
        >
          <Icon path={mdiInformationOutline} size={0.75} />
        </IconButton>
      </MDBox>
    </DashboardContentLayout>
  );
};

export default Support;
