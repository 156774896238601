import {
  MediaHubAPIServices,
  MediaHubAlbumServicesAPI,
  WebServiceResponse,
} from "utils/services/AppUrls";
import {
  performGetRequest,
  performPostRequest,
} from "utils/services/NetworkHandler";

export const getClientMediaInfo = async (
  parameters: string
): Promise<WebServiceResponse> => {
  const url = MediaHubAPIServices.getClientMediaInfo + "?" + parameters;
  return await performGetRequest(url);
};

export const getClientMediaAlbumsInfo = async (
  parameters: string
): Promise<WebServiceResponse> => {
  const url = `${MediaHubAPIServices.getClientMediaInfo}/albums?${parameters}`;
  return await performGetRequest(url);
};

// Fetches all albums in the logged in user's company
export const getAllAlbumsInMyCompany =
  async (): Promise<WebServiceResponse> => {
    return await performGetRequest(
      MediaHubAlbumServicesAPI.getAlbumTagsByMyCompanyId
    );
  };

// Creates new album name
export const createNewAlbum = async (
  albumName: string
): Promise<WebServiceResponse> => {
  const parameter = { name: albumName };
  return await performPostRequest(
    MediaHubAlbumServicesAPI.addAlbumTag,
    parameter
  );
};

// Rename existing album
export const renameAlbumNameWithAlbumId = async (
  albumId: string,
  albumName: string
): Promise<WebServiceResponse> => {
  const parameter = { name: albumName };
  const url = `${MediaHubAlbumServicesAPI.editAlbumTagById}/${albumId}`;
  return await performPostRequest(url, parameter);
};

// Delete an album
export const deleteAlbumWithAlbumId = async (
  albumId: string
): Promise<WebServiceResponse> => {
  const url = `${MediaHubAlbumServicesAPI.deleteAlbumTag}/${albumId}`;
  return await performPostRequest(url, {});
};

// To add one or more medias to one or more albums
export const addMediasToAlbum = async (
  albumIds: string[],
  mediaFileIds: string[]
): Promise<WebServiceResponse> => {
  const parameter = { fileIds: mediaFileIds, tagIds: albumIds };
  const url = MediaHubAlbumServicesAPI.addMediaFileAssociationByAlbumId;
  return await performPostRequest(url, parameter);
};

// To export albums as PDF
export const exportAlbumsAsPDF = async (
  albumID: string
): Promise<WebServiceResponse> => {
  const url = `${MediaHubAPIServices.getClientMediaInfo}/albums/${albumID}/pdf`;
  return await performGetRequest(url);
};

// To remove medias from an album
export const removesMediasFromAlbum = async (
  albumId: string,
  mediaFileIds: string[]
): Promise<WebServiceResponse> => {
  const parameter = { fileIds: mediaFileIds };
  const url = `${MediaHubAlbumServicesAPI.deleteMediaFileAssociationByAlbumId}/${albumId}`;
  return await performPostRequest(url, parameter);
};

export const hideMediaItems = async (
  hideMedia: boolean,
  fileIds: string[]
): Promise<WebServiceResponse> => {
  const parameter = {
    // For CP, adminHide is always false
    adminHide: false,
    clientHide: hideMedia,
    fileIds: fileIds,
  };
  const url = MediaHubAPIServices.hideMediaFiles;
  return await performPostRequest(url, parameter);
};
