import { Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { VueMapSection } from "./VueMapSection";
import { useVueProvider } from "pages/dashboard/home/vues/context/VueProvider";
import { PlaceholderImage } from "./PlaceholderImage";
import { VueMediaSection } from "./VueMediaSection";
import {
  IndividualVue,
  PhotoFileInfo,
  VideoFileInfo,
} from "../utils/vue_detail_interface";
import { getVueStatus } from "../../vue-grid/utils/enums";

export interface Props {
  vueDetail: IndividualVue;
  isReadOnly: boolean;
}

export const VueMapAndMediaSection = ({ vueDetail, isReadOnly }: Props) => {
  /// To get the latest stored vue image detail from local storage
  const { latestSelectedVueImage } = useVueProvider();
  const vueStatus = getVueStatus(vueDetail.status);
  /// State to store the selected media according to the marker hover
  const [currentPhotoInfo, setCurrentPhotoInfo] =
    useState<PhotoFileInfo | null>();
  const [currentVideoInfo, setCurrentVideoInfo] =
    useState<VideoFileInfo | null>();

  useEffect(() => {
    if (latestSelectedVueImage && vueStatus.toUpperCase() !== "IN REVIEW") {
      /// Checking whether there is any previously selected photo / locally stored
      const key = Object.keys(latestSelectedVueImage)[0];
      const locallyStoredMedia = latestSelectedVueImage[key];
      /// If so, then checks whether the vue is same & then setting the marker ID, coords
      /// So that, map will render with that coords in the center
      if (locallyStoredMedia && key === vueDetail.vue.canonicalId) {
        if (locallyStoredMedia.mimeType === "video/mp4") {
          setCurrentVideoInfo(locallyStoredMedia);
          setCurrentPhotoInfo(null);
        } else {
          setCurrentPhotoInfo(locallyStoredMedia);
          setCurrentVideoInfo(null);
        }
      }
    }
  }, [latestSelectedVueImage, vueDetail, vueStatus]);

  return (
    <Grid container spacing={2.49}>
      <Grid item xs={6}>
        <VueMapSection vue={vueDetail} isReadOnly={isReadOnly} />
      </Grid>
      <Grid
        item
        xs={6}
        sx={{
          ".MuiCardMedia-media, video": {
            maxHeight: "356px",
            marginLeft: "auto !important",
            marginRight: "auto !important",
          },
          video: {
            height: "356px !important",
          },
          ".heightFix": {
            height: "356px !important",
          },
        }}
      >
        {currentPhotoInfo ? (
          <VueMediaSection
            photoFileInfo={currentPhotoInfo}
            isReadOnly={isReadOnly}
          />
        ) : currentVideoInfo ? (
          <VueMediaSection
            videoFileInfo={currentVideoInfo}
            isStepTypeVideo={true}
            isReadOnly={isReadOnly}
          />
        ) : (
          <PlaceholderImage />
        )}
      </Grid>
    </Grid>
  );
};
